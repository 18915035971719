import { DeleteCommentDialog } from "#src/batteries-included-components/Dialogs/DeleteCommentDialog";
import { CommentForm } from "#src/batteries-included-components/Forms/CommentForm";
import { useGetOneCommentFeed } from "#src/components/hooks/adapters/useComments";
import {
  useCheckCommentPermissions,
  useScrollToComment,
  useDeleteComment,
  useCommentsStore,
} from "#src/components/hooks/useComments";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import {
  EmptyState,
  Icon,
  Message,
  TimelinePanel,
} from "@validereinc/common-components";
import {
  CommentEntity,
  CommentSchemaType,
  WorkflowTaskType,
  WorkflowType,
} from "@validereinc/domain";
import { datetimeFormatter } from "@validereinc/utilities";
import classNames from "classnames/bind";
import { isEqual } from "lodash";
import React, { useEffect, useMemo } from "react";
import styles from "./TaskCommentsDrawer.module.css";

const cx = classNames.bind(styles);

const TaskCommentsDrawer = ({
  workflow,
  task,
}: {
  workflow: WorkflowType;
  task: WorkflowTaskType;
}) => {
  const workflowId = workflow.id;

  const {
    v2: {
      userInfo: { user },
    },
  } = useAuthenticatedContext();
  const {
    canReadComments,
    canWriteComments,
    canDeleteCommentsAsAdmin,
    canReadAssetType,
    canWriteAssetType,
  } = useCheckCommentPermissions(workflow.asset?.asset_type);
  const {
    commentsEndRef,
    shouldScrollToBottom,
    newlyCreatedCommentId,
    handleCreateComment,
  } = useScrollToComment();
  const {
    handleDeleteComment,
    showDeleteCommentDialog,
    setShowDeleteCommentDialog,
    commentIdToDelete,
    setCommentIdToDelete,
  } = useDeleteComment();
  const { setCommentCounts } = useCommentsStore();

  const { data: taskCommentsData } = useGetOneCommentFeed(
    {
      id: workflowId,
      meta: {
        entity_type: CommentEntity.WORKFLOW,
        additional_identifier: task.id,
        created_before: "",
      },
    },
    {
      enabled:
        Boolean(workflowId) &&
        Boolean(task.id) &&
        canReadComments &&
        canReadAssetType,
    }
  );

  const taskComments = useMemo(
    () =>
      taskCommentsData?.data
        .filter((taskComment) => !taskComment.is_deleted)
        // The API sends the comments in descending order by commented_at
        .reverse() ?? [],
    [taskCommentsData]
  );

  useEffect(() => {
    if (commentsEndRef.current && shouldScrollToBottom) {
      commentsEndRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [taskComments, shouldScrollToBottom]);

  useEffect(() => {
    setCommentCounts(`taskComments-${task.id}`, taskComments.length);
  }, [taskComments]);

  const canCreateComments = canWriteComments && canWriteAssetType;
  const hasComments = taskComments.length;

  return (
    <>
      <TimelinePanel.Drawer
        shouldRenderPortal={false}
        drawerKey="taskComments"
      >
        <TimelinePanel.Header title="Comments" />
        <TimelinePanel.Content
          className={cx("comment-panel-content", !hasComments && "no-overflow")}
        >
          {hasComments ? (
            <>
              {taskComments.map((taskComment: CommentSchemaType) => (
                <Message
                  className={cx(
                    taskComment.id === newlyCreatedCommentId &&
                      "play-flash-animation"
                  )}
                  key={taskComment.id}
                  name={taskComment.user?.name}
                  createdAt={datetimeFormatter(
                    new Date(taskComment.commented_at ?? "")
                  )}
                  textContent={taskComment.text}
                  {...(canDeleteCommentsAsAdmin ||
                  (canWriteComments && user?.id === taskComment.user?.id)
                    ? {
                        actions: [
                          {
                            label: "Delete",
                            onClick: () => {
                              setShowDeleteCommentDialog(true);
                              setCommentIdToDelete(taskComment.id);
                            },
                            slotLeft: () => <Icon variant="trash" />,
                            variant: "danger",
                          },
                        ],
                      }
                    : undefined)}
                />
              ))}
              <div ref={commentsEndRef} />
            </>
          ) : (
            <EmptyState
              className={cx("comments-empty-state")}
              title="No comments yet."
              icon={<Icon variant="chat-text" />}
            />
          )}
        </TimelinePanel.Content>
        {canCreateComments ? (
          <TimelinePanel.Footer className={cx("comment-panel-footer")}>
            <CommentForm
              entity_type={CommentEntity.WORKFLOW}
              entity_id={workflowId}
              additional_identifier={task.id}
              onCreateCallback={handleCreateComment}
              canCreateComments={canCreateComments}
            />
          </TimelinePanel.Footer>
        ) : null}
      </TimelinePanel.Drawer>
      <DeleteCommentDialog
        isOpen={showDeleteCommentDialog}
        onClose={() => {
          setShowDeleteCommentDialog(false);
        }}
        commentIdToDelete={commentIdToDelete ?? ""}
        handleDelete={handleDeleteComment}
      />
    </>
  );
};

export default React.memo(TaskCommentsDrawer, isEqual);
