import { RecordFlowSummaryPanel } from "#batteries-included-components/Panels/SummaryPanels/RecordFlowSummaryPanel";
import { RecordValueTablePanel } from "#batteries-included-components/Panels/TablePanels/RecordValueTablePanel";
import { useGetOneFlow } from "#src/components/hooks/adapters/useFlows";
import { useActionDetails } from "#src/components/hooks/useActionDetails";
import { RecordContext, RecordProvider } from "#src/contexts/RecordContext";
import useLocalization from "#src/hooks/useLocalization";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { Page, Pill, PillProps } from "@validereinc/common-components";
import { getRecordStatusFromValues } from "@validereinc/domain";
import { yearMonthName } from "@validereinc/utilities";
import React, { useContext } from "react";
import { useParams } from "react-router";
import { FlowRecordDetailRoute } from "./index";

const FlowRecordDetailPageContent = ({ flowId }: { flowId: string }) => {
  const { localize } = useLocalization();
  const { record, isLoading } = useContext(RecordContext) ?? {};
  const { data: flow, isLoading: isFlowLoading } = useGetOneFlow(
    { flowId, period: record?.year_month },
    { enabled: !!record?.year_month }
  );

  const pageTitle = record
    ? `${yearMonthName(record.year_month)} Record`
    : FlowRecordDetailRoute.title;

  const editInfo = useActionDetails({
    type: "edit",
    date: record?.updated_at,
    byUserId: record?.updated_by,
  });

  const [breadcrumbs] = useBreadcrumbsFromRoute(FlowRecordDetailRoute, {
    detail: { title: flow?.name },
    recordId: { title: pageTitle },
  });

  const lockStatus = record?.values
    ? getRecordStatusFromValues(record.values)
    : "";

  const recordStatusOptions: Record<
    string,
    { label: string; variant: PillProps["variant"] }
  > = {
    locked: { label: "Locked", variant: "default" },
    partially_locked: { label: "Partially Locked", variant: "info" },
    unlocked: { label: "Unlocked", variant: "info" },
  };

  return (
    <Page
      category={`${localize("Flow")} Record`}
      title={
        !record ? (
          pageTitle
        ) : (
          <>
            {pageTitle}&nbsp;
            <Pill
              variant={
                lockStatus ? recordStatusOptions[lockStatus].variant : "default"
              }
              hasDot={false}
            >
              {lockStatus ? recordStatusOptions[lockStatus].label : ""}
            </Pill>
          </>
        )
      }
      breadcrumbs={breadcrumbs}
      isLoading={isLoading || isFlowLoading}
      renderMeta={
        !editInfo.description
          ? undefined
          : ({ MetaSegments }) => (
              <MetaSegments values={[editInfo.description]} />
            )
      }
    >
      {record ? (
        <RecordFlowSummaryPanel
          flowId={flowId}
          period={record?.year_month}
        />
      ) : null}
      <RecordValueTablePanel />
    </Page>
  );
};

export const FlowRecordDetailPage = () => {
  const { flowId } = useParams<{ flowId: string }>();

  return (
    <RecordProvider>
      <FlowRecordDetailPageContent flowId={flowId} />
    </RecordProvider>
  );
};
