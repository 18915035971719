import { z } from "zod";
import { NodeAPIFilterSchema } from "./FilterSchemas";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
} from "./DomainModelSchemas";

export const PermissionGrant = {
  READ: "read",
  READ_ALL: "read_all",
  WRITE: "write",
  EDIT: "edit",
  DELETE: "delete",
  DELETE_ADMIN: "delete_admin",
  LOCK: "lock",
} as const;

export const checkIsPermissionGrantType = (
  grant: string
): grant is PermissionGrantType =>
  Object.values(PermissionGrant).some((g) => g === grant);

export const checkIsPermissionNameType = (
  permissionName: string
): permissionName is PermissionNameType =>
  new RegExp(
    /^[a-z]+[a-z_]+:(read|read_all|write|edit|delete|delete_admin|lock)$/
  ).test(permissionName);

export const PermissionSchema = z.object({
  name: z
    .string()
    .regex(
      new RegExp(
        /^[a-z]+[a-z_]+:(read|read_all|write|edit|delete|delete_admin|lock)$/
      )
    ),
});

export const UserPermissionSchema = z.object({
  name: z
    .string()
    .regex(
      new RegExp(
        /^[a-z]+[a-z_]+:(read|read_all|write|edit|delete|delete_admin|lock)$/
      )
    ),
  active: z.boolean(),
  filter: NodeAPIFilterSchema,
});

export const RolePermissionSchema = UserPermissionSchema.merge(
  z.object({
    group_id: z.string().uuid(),
  })
)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema);

export type PermissionType = z.infer<typeof PermissionSchema>;
export type PermissionGrantType =
  (typeof PermissionGrant)[keyof typeof PermissionGrant];
export type PermissionNameType = `${string}:${PermissionGrantType}`;
export type UserPermissionType = z.infer<typeof UserPermissionSchema>;
export type RolePermissionType = z.infer<typeof RolePermissionSchema>;
