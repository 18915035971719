import { useActionDetails } from "./useActionDetails";

type UseCreateUpdateMetaSegmentsProps = {
  createdAt?: string | null;
  createdBy?: string | null;
  editedAt?: string | null;
  editedBy?: string | null;
};

export function useCreateEditMetaSegments(
  props: UseCreateUpdateMetaSegmentsProps
) {
  const createInformation = useActionDetails({
    type: "create",
    date: props.createdAt,
    byUserId: props.createdBy,
  });
  const updateInformation = useActionDetails({
    type: "edit",
    date: props.editedAt,
    byUserId: props.editedBy,
  });
  return [updateInformation.description, createInformation.description].filter(
    (s) => s !== undefined
  );
}
