import moment from "moment";
import "moment-timezone";
import config from "../config";

export const DEFAULT_TIMEZONE = "Etc/UTC";

/** @deprecated use date utilities from our @validereinc/utilities package instead */
export const TIMEZONE = moment.tz.guess()
  ? moment.tz.guess()
  : DEFAULT_TIMEZONE;

/** @deprecated use date utilities from our @validereinc/utilities package instead */
export function getTimeStringFromUTC(utcTime, format) {
  if (utcTime) {
    return moment.unix(utcTime).tz(TIMEZONE).format(format);
  }
  return "-";
}

/** @deprecated use date utilities from our @validereinc/utilities package instead */
export function getTimeStringFromNow(
  epochTime,
  format,
  startString,
  endString
) {
  if (!startString) {
    startString = "";
  }

  if (!endString) {
    endString = "";
  }

  if (epochTime) {
    return (
      startString +
      " " +
      convertEpochToMoment(epochTime).fromNow(true) +
      " " +
      endString
    );
  }

  return "-";
}

/** @deprecated use date utilities from our @validereinc/utilities package instead */
export function getTimeStringFromDate(date, format) {
  if (date) {
    return moment(date).tz(TIMEZONE).format(format);
  }

  return "-";
}

/** @deprecated use date utilities from our utilities package instead */
export function getTimeStringFromUTCDate(date, format) {
  if (date) {
    return getTimeStringFromDate(moment.utc(date), format);
  }

  return "-";
}

/**
 * @deprecated use date utilities from our utilities package instead
 *
 * Takes in a date object in moment's UTC mode (which converts a date to UTC and only displays in UTC) and formats it as a string
 * @param {Date} date valid JS Date object
 * @param {string} format valid moment format string. optional. if not provided, the format will be in ISO 8601 format. {@link https://momentjs.com/docs/#/displaying/as-iso-string/}
 * @returns a datetime string
 */
export function getUTCTimeString(date: Date, format?: string) {
  if (date) {
    return format
      ? moment.utc(date).format(format)
      : moment.utc(date).toISOString();
  }

  return "-";
}

/** @deprecated use date utilities from our @validereinc/utilities package instead */
export function convertEpochToMoment(epochTime) {
  if (epochTime) {
    return moment.unix(epochTime).tz(TIMEZONE);
  }
}

/** @deprecated use date utilities from our @validereinc/utilities package instead */
export function formatTime(timeObject, format) {
  if (timeObject) {
    return moment(timeObject).format(format);
  }
  return "-";
}

/** @deprecated use date utilities from our @validereinc/utilities package instead */
export function getDateRangeFromEpoch(startEpoch, endEpoch) {
  if (startEpoch && endEpoch) {
    return (
      getTimeStringFromUTC(startEpoch, config.DATE_FORMAT) +
      " - " +
      getTimeStringFromUTC(endEpoch, config.DATE_FORMAT)
    );
  } else if (startEpoch) {
    return getTimeStringFromUTC(startEpoch, config.DATE_FORMAT);
  } else {
    return getTimeStringFromUTC(endEpoch, config.DATE_FORMAT);
  }
}

/** @deprecated use date utilities from our @validereinc/utilities package instead */
export function getDateRangeFromDate(startDate, endDate) {
  if (startDate && endDate) {
    return (
      getTimeStringFromDate(startDate, "MM/DD") +
      " - " +
      getTimeStringFromDate(endDate, "MM/DD/YYYY")
    );
  } else if (startDate) {
    return getTimeStringFromDate(startDate, "MM/DD/YYYY");
  } else {
    return getTimeStringFromDate(endDate, "MM/DD/YYYY");
  }
}

/** @deprecated use date utilities from our @validereinc/utilities package instead */
export function getDateTimeRangeFromDate(startDate, endDate) {
  if (startDate && endDate) {
    return (
      getTimeStringFromDate(startDate, "MM/DD H:mm:ss") +
      " - " +
      getTimeStringFromDate(endDate, "MM/DD/YYYY H:mm:ss z")
    );
  } else if (startDate) {
    return getTimeStringFromDate(startDate, "MM/DD/YYYY H:mm:ss z");
  } else {
    return getTimeStringFromDate(endDate, "MM/DD/YYYY H:mm:ss z");
  }
}

/** @deprecated use date utilities from our @validereinc/utilities package instead */
export function offsetTimezoneToGMT(time, type, range) {
  if (type === "startOf") {
    return moment(time).utc().startOf(range).toDate();
  } else {
    return moment(time).utc().endOf(range).toDate();
  }
}
