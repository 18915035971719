import { CommentAdapter, Resources } from "@validereinc/domain";
import { CommentSchemaType } from "node_modules/@validereinc/domain/src/schemas/CommentSchemas";
import { useCreateOne, useDeleteOne, useGetOne } from "./adapterUtils";

export const useGetOneCommentFeed = useGetOne<CommentSchemaType[]>(
  CommentAdapter.feed.getOne,
  Resources.COMMENT
);

export const useCreateOneComment = useCreateOne<CommentSchemaType>(
  CommentAdapter.createOne,
  Resources.COMMENT
);

export const useDeleteOneComment = useDeleteOne<CommentSchemaType>(
  CommentAdapter.deleteOne,
  Resources.COMMENT
);
