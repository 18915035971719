import { TemplatedConfigurationRunDrawer } from "#src/batteries-included-components/Drawers/TemplatedConfigurationRunDrawer";
import { EstimationMethodDetailViewFilterArea } from "#src/batteries-included-components/FilterAreas/EstimationMethodsFilterAreas/EstimationMethodsFilterAreas";
import { IsFeatureAvailable } from "#src/batteries-included-components/Layouts/Authorization/IsFeatureAvailable";
import { useEstimationMethodDetailContext } from "#src/batteries-included-components/Layouts/EstimationMethod/Detail";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useQuery } from "@tanstack/react-query";
import {
  MathDataDisplayEquationsWithBreakdown,
  Panel,
} from "@validereinc/common-components";
import {
  Resources,
  TemplatedConfigurationRunAdapter,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useState } from "react";
import EstimationMethodDetailsPanel, {
  EstimationMethodDetailsPanelProps,
} from "./EstimationMethodDetailsPanel";
import styles from "./EstimationMethodOverviewTab.module.scss";

const cx = classNames.bind(styles);

const EstimationMethodOverviewTab = ({
  detailsPanelProps = {},
}: EstimationMethodOverviewTabProps) => {
  const { method, calculator } = useEstimationMethodDetailContext();
  const [isTemplatedConfigsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:templated_configurations",
    permissionQuery: "templated_configurations:read",
  });
  const { viewConfigStorageKey } = useStorageKey("estimation-method-overview");

  const [
    isTemplatedConfigurationRunDrawerOpen,
    setIsTemplatedConfigurationRunDrawerOpen,
  ] = useState(false);

  const resourceId = method?.data?.id ?? "";

  const templatedConfigurationRunDependencyGraphQuery = useQuery({
    queryKey: [
      Resources.TEMPLATED_CONFIGURATION_RUN,
      "dependencyGraph",
      resourceId,
    ],
    queryFn: () =>
      TemplatedConfigurationRunAdapter.dependencyGraph.getOne({
        id: resourceId,
      }),
    enabled: isTemplatedConfigsAvailable && !!resourceId,
  });

  const templatedConfigurationRunDependencyGraph =
    templatedConfigurationRunDependencyGraphQuery?.data?.data;

  const calculatorDetail = calculator?.data;
  const lastCalculatorVersion = calculatorDetail?.versions.find(
    ({ version }: { version: string }) =>
      version === calculatorDetail?.default_version
  );

  return (
    <div className={cx("container")}>
      <EstimationMethodDetailViewFilterArea
        viewConfigStorageKey={viewConfigStorageKey}
      />
      <div className={cx("container")}>
        <EstimationMethodDetailsPanel {...detailsPanelProps} />
        {typeof lastCalculatorVersion?.documentation === "object" &&
        Object.keys(lastCalculatorVersion?.documentation ?? {}).length ? (
          <Panel
            isFluidY={false}
            style={{ marginBottom: 0 }}
          >
            <MathDataDisplayEquationsWithBreakdown
              title={lastCalculatorVersion?.documentation.title}
              sourceLink={lastCalculatorVersion?.documentation.link}
              equations={lastCalculatorVersion?.documentation.calculations.map(
                (calc) => ({
                  equation: calc.equation,
                  reference: calc.reference,
                  terms: calc.conditions.map((cond) => ({
                    math: cond.variable_name,
                    description: cond.variable_description,
                  })),
                })
              )}
              isLoading={calculator?.status === "loading"}
            />
          </Panel>
        ) : null}
      </div>
      <IsFeatureAvailable
        featureFlagQuery="core:templated_configurations"
        permissionQuery="templated_configurations:read"
        fallbackChildren={null}
      >
        <TemplatedConfigurationRunDrawer
          isOpen={isTemplatedConfigurationRunDrawerOpen}
          onClose={() => setIsTemplatedConfigurationRunDrawerOpen(false)}
          templatedConfigurationRunDependencyGraph={
            templatedConfigurationRunDependencyGraph
          }
          resourceId={resourceId}
        />
      </IsFeatureAvailable>
    </div>
  );
};

export type EstimationMethodOverviewTabProps = {
  detailsPanelProps?: EstimationMethodDetailsPanelProps;
};

export default EstimationMethodOverviewTab;
