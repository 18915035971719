import { Button, Dialog } from "@validereinc/common-components";
import React from "react";

const DELETE_COMMENT_DIALOG_TITLE = "Delete Comment?";
const DELETE_COMMENT_DIALOG_DESCRIPTION = `Are you sure you want to delete this comment? This action cannot be undone.`;

export const DeleteCommentDialog = ({
  isOpen,
  onClose,
  commentIdToDelete,
  handleDelete,
}: {
  isOpen: boolean;
  onClose: () => void;
  commentIdToDelete: string;
  handleDelete: (commentIdToDelete: string) => void;
}) => {
  return (
    <Dialog
      title={DELETE_COMMENT_DIALOG_TITLE}
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          key="delete-comment-action"
          variant="error"
          onClick={() => {
            handleDelete(commentIdToDelete);
            onClose();
          }}
        >
          Delete
        </Button>,
      ]}
    >
      {DELETE_COMMENT_DIALOG_DESCRIPTION}
    </Dialog>
  );
};
