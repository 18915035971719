import DeleteModal from "#common/DeleteModal/DeleteModal";
import FormTable from "#common/Forms/FormTable";
import { useFormCategories } from "#hooks/useForms";
import useMeasurementTypes from "#hooks/useMeasurementTypes";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "#routers/hooks";
import MeasurementsService from "#services/MeasurementsService";
import { NODE_API_MAX_PAGE_SIZE } from "#services/ServiceHelper";
import EditDeviceDialog from "#src/batteries-included-components/Dialogs/EditDeviceDialog";
import { DevicesViewFilterArea } from "#src/batteries-included-components/FilterAreas/AssetsFilterAreas";
import { useDeleteDevice } from "#src/components/hooks/adapters/utils/useDevices";
import { useCreateEditMetaSegments } from "#src/components/hooks/useCreateEditMetaSegments";
import { AGGREGATOR } from "#src/constants";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { DevicesListRoutePath } from "#src/routes/organization/devices";
import { DevicesDetailRoutePath } from "#src/routes/organization/devices/[deviceId]/detail";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { getIntervalOptions } from "#utils/date";
import { Button, Page, Pill, Tab } from "@validereinc/common-components";
import { AssetType, Device, DeviceType } from "@validereinc/domain";
import { getYearMonthFromDateRange } from "@validereinc/utilities";
import React, { useEffect, useMemo, useState } from "react";
import { ChangeLogTab } from "./ChangeLogTab";
import OverviewTab from "./DeviceOverviewTab/DeviceOverviewTab";
import MeasurementsTab from "./MeasurementsTab/MeasurementsTab";
import { fetchMeasurementData, validateFilters } from "./deviceDetailHelper";
import { useCreateUpdateMetaSegments } from "#src/components/hooks/useCreateUpdateMetaSegments";
import useLocalization from "#src/hooks/useLocalization";

const DEFAULT_TAB_KEY = "overview";
const ENTITY_TYPE = AssetType.DEVICE;

const DeviceDetail = () => {
  const navigate = useNavigate();
  const { localize } = useLocalization();
  const location = useLocation();
  const [isChangeLogAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:activity_logs",
    permissionQuery: "activities:read",
  });
  const assetsSharedStorageKeys = useStorageKey("assets-shared");
  const [filters] = useSessionStickyState<{
    period: { from: string; to: string };
  }>({}, assetsSharedStorageKeys.viewConfigStorageKey);
  const { deviceId } = useParams<{ deviceId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [deviceDetail, setDeviceDetail] = useState<DeviceType>();
  const [breadcrumbs] = useBreadcrumbsFromRoute(DevicesDetailRoutePath, {
    "/details": {
      title: deviceDetail?.name,
    },
  });
  const [measurementData, setMeasurementData] = useState([]);
  const [measurementDataCacheKey, setMeasurementDataCacheKey] = useState("");
  const [formCategories] = useFormCategories({
    relatedEntityId: deviceId,
    relatedEntityType: ENTITY_TYPE,
  });

  const [measurementSources, setMeasurementSources] = useState([]);
  const [assetIdToEdit, setAssetIdToEdit] = useState<string | undefined>();

  const allowedMeasurementTypes = measurementSources.map(
    ({ measurement_type }) => measurement_type
  );

  const [unfilteredMeasurementTypes] = useMeasurementTypes();
  const measurementTypes = useMemo(
    () =>
      unfilteredMeasurementTypes.filter(({ id }) =>
        allowedMeasurementTypes.includes(id)
      ),
    [unfilteredMeasurementTypes, measurementSources]
  );

  const [intervalOptions, setIntervalOptions] = useState(() =>
    getIntervalOptions(DEFAULT_DATE_RANGES.last30Days)
  );

  const [appliedFilters, setAppliedFilters] = useState({
    date_range:
      searchParams?.from && searchParams.to
        ? { from: new Date(searchParams?.from), to: new Date(searchParams.to) }
        : DEFAULT_DATE_RANGES.last30Days,
    interval: searchParams?.interval || intervalOptions[0].id,
    measurement_left: searchParams?.measurement_left,
    measurement_right: searchParams?.measurement_right,
    aggregation_function:
      searchParams?.aggregation_function || AGGREGATOR.AVERAGE,
  });

  const filteredMeasurementData = useMemo(
    () =>
      [appliedFilters.measurement_left, appliedFilters.measurement_right]
        .map((measurementType) =>
          measurementData.find(({ type }) => type === measurementType)
        )
        .filter((value) => value),
    [measurementData, appliedFilters]
  );

  // Provide reasonable intervals based on date range
  useEffect(() => {
    const newIntervals = getIntervalOptions(appliedFilters.date_range);
    setIntervalOptions(newIntervals);

    if (!newIntervals.map(({ id }) => id).includes(appliedFilters.interval)) {
      setAppliedFilters({ ...appliedFilters, interval: newIntervals[0].id });
    }
  }, [appliedFilters.date_range]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Fetch Device Detail & Measurement Sources
  useEffect(() => {
    if (deviceId) {
      setIsLoading(true);

      Promise.all([
        Device.getOne({
          id: deviceId,
          meta: { period: getYearMonthFromDateRange(filters?.period) },
        }),
        MeasurementsService.getMeasurementSources({
          deviceId,
          rowPerPage: NODE_API_MAX_PAGE_SIZE,
        }),
      ])
        .then(([{ data: detailData }, { data: sourcesData }]) => {
          setMeasurementSources(sourcesData.data);
          setDeviceDetail(detailData);
          setAppliedFilters(validateFilters(appliedFilters, sourcesData.data));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [deviceId, filters.period]);
  const createEditMetaSegments = useCreateEditMetaSegments({
    editedAt: deviceDetail?.updated_at,
    createdAt: deviceDetail?.created_at,
  });

  // Fetch Measurements
  useEffect(async () => {
    if (!isLoading && measurementSources.length === 0) {
      setIsDataLoading(false);
    }
    if (measurementSources.length && measurementTypes.length) {
      const {
        measurement_left: _measurement_left,
        measurement_right: _measurement_right,
        ...filters
      } = appliedFilters;
      const cacheKey = JSON.stringify(filters);
      // Don't re-fetch data if only the measurement_types have changed
      if (measurementDataCacheKey === cacheKey) {
        return;
      }
      setMeasurementDataCacheKey(cacheKey);

      setIsDataLoading(true);

      const data = await fetchMeasurementData(
        deviceId,
        measurementSources,
        measurementTypes,
        filters
      );

      setMeasurementData(data);
      setIsDataLoading(false);
    }
  }, [measurementSources, measurementTypes, appliedFilters, isLoading]);

  const onFiltersChange = async (newFilters) => {
    if (!newFilters.measurement_left) {
      return;
    }

    setAppliedFilters(newFilters);

    const {
      date_range: { from, to },
      ...restFilters
    } = newFilters;

    navigate({
      pathname: location.pathname,
      query: { ...restFilters, from, to, tab: "measurements" },
      replace: true,
    });
  };

  const onEditDevice = () => setAssetIdToEdit(deviceId);

  const actionRow = (
    <>
      <Button
        variant="error-outline"
        onClick={() => setShowDeleteModal(true)}
      >
        Delete
      </Button>

      <Button
        variant="primary"
        onClick={onEditDevice}
      >
        Edit
      </Button>
    </>
  );

  const handleActiveTabKeyChange = (tabKey) => {
    setSearchParams({ ...searchParams, tab: tabKey });
  };

  const { mutate: deleteDevice } = useDeleteDevice();

  return (
    <>
      <Page
        category={DevicesDetailRoutePath.previous?.title}
        title={
          deviceDetail ? (
            <>
              {deviceDetail.name}{" "}
              <Pill
                variant={
                  deviceDetail.status === "active" ? "success" : "default"
                }
                hasDot={false}
              >
                {deviceDetail.status}
              </Pill>
            </>
          ) : (
            DevicesDetailRoutePath.title
          )
        }
        breadcrumbs={breadcrumbs}
        actionRow={actionRow}
        onActiveTabKeyChange={handleActiveTabKeyChange}
        activeTabKey={
          !isChangeLogAvailable && searchParams?.tab === "change-log"
            ? DEFAULT_TAB_KEY
            : searchParams?.tab ?? DEFAULT_TAB_KEY
        }
        renderMeta={
          createEditMetaSegments.length === 0
            ? undefined
            : ({ MetaSegments }) => (
                <MetaSegments values={createEditMetaSegments} />
              )
        }
      >
        <Tab
          tabKey="overview"
          title="Overview"
        >
          <OverviewTab
            isLoading={isLoading}
            deviceDetail={deviceDetail}
            viewFilterAreaSlot={
              <DevicesViewFilterArea
                viewConfigStorageKey={
                  assetsSharedStorageKeys.viewConfigStorageKey
                }
              />
            }
          />
        </Tab>
        <Tab
          tabKey="measurements"
          title="Measurements"
        >
          <MeasurementsTab
            appliedFilters={appliedFilters}
            onFiltersChange={onFiltersChange}
            measurementTypes={measurementTypes}
            intervalOptions={intervalOptions}
            filteredMeasurementData={filteredMeasurementData}
            isDataLoading={isDataLoading}
            measurementSources={measurementSources}
          />
        </Tab>

        <Tab
          tabKey="change-log"
          title="Change Log"
          isDisabled={!isChangeLogAvailable}
          iconVariant={!isChangeLogAvailable ? "lock" : ""}
        >
          {isChangeLogAvailable ? <ChangeLogTab resourceId={deviceId} /> : null}
        </Tab>

        {formCategories.map((formCategory) => (
          <Tab
            key={formCategory?.id}
            tabKey={formCategory?.id}
            title={formCategory.name}
          >
            <FormTable
              formCategoryId={formCategory?.id}
              relatedEntityId={deviceId}
              relatedEntityType={ENTITY_TYPE}
            />
          </Tab>
        ))}
      </Page>

      <DeleteModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        doDelete={() => {
          deleteDevice(deviceId);
        }}
        onDelete={() => navigate(DevicesListRoutePath.toLinkParts())}
        entityName={deviceDetail?.type?.name ?? "Emissions Monitor"}
        instanceName={deviceDetail?.name ?? deviceId}
      />

      <EditDeviceDialog
        onClose={() => setAssetIdToEdit(undefined)}
        deviceId={assetIdToEdit}
      />
    </>
  );
};

export default DeviceDetail;
