import { z } from "zod";
import {
  AttributeDataType,
  AttributeIntegerSchema,
  AttributeLookupSchema,
  AttributeMultiPicklistSchema,
  AttributeNumberSchema,
  AttributePicklistSchema,
  AttributeSchema,
} from "./AttributeSchemas";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";
import { NodeAPIFilterSchema } from "./FilterSchemas";
import { FormCategorySchema } from "./FormCategoriesSchemas";
import { MeasurementTypes } from "./MeasurementTypeSchemas";
import { FormSubmissionStatus } from "./FormSubmissionSchemas.shared";

export const FormSchemaStatus = {
  ACTIVE: "active",
  ARCHIVED: "archived",
  DRAFT: "draft",
} as const;
export type FormSchemaStatusType =
  (typeof FormSchemaStatus)[keyof typeof FormSchemaStatus];

export const FormSchemaQuestionType = {
  QUESTION: "question",
  MEASUREMENT: "measurement",
  CALCULATED_MEASUREMENT: "calculated measurement",
} as const;
export type FormSchemaQuestionTypeType =
  (typeof FormSchemaQuestionType)[keyof typeof FormSchemaQuestionType];

export const FormSchemaQuestionAnswerSchema = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  z.union([z.array(z.string()), z.array(z.number())]),
]);
export type FormSchemaQuestionAnswerType = z.infer<
  typeof FormSchemaQuestionAnswerSchema
>;

export const FormSchemaQuestionDefaultAnswerLookupSchema = z.object({
  lookup_question_id: z.string(),
  lookup_attribute: z.string(),
});
export type FormSchemaQuestionDefaultAnswerLookupType = z.infer<
  typeof FormSchemaQuestionDefaultAnswerLookupSchema
>;

export const FormSchemaDefaultAnswerSchema = z.union([
  FormSchemaQuestionAnswerSchema,
  FormSchemaQuestionDefaultAnswerLookupSchema,
]);
export type FormSchemaDefaultAnswerType = z.infer<
  typeof FormSchemaDefaultAnswerSchema
>;

export const FormSchemaQuestionBaseSchema = z.object({
  prompt: z.string(),
  description: z.string(),
  conditions: NodeAPIFilterSchema.optional(),
  default_answer: FormSchemaDefaultAnswerSchema.optional(),
  is_required: z.boolean(),
  is_long_string: z.boolean().optional(),
  equation: z.string().optional(),
  minimum: z.number().optional(),
  maximum: z.number().optional(),
  min_length: z.number().optional(),
  max_length: z.number().optional(),
  before: z.string().optional(),
  after: z.string().optional(),
});
export type FormSchemaQuestionBaseType = z.infer<
  typeof FormSchemaQuestionBaseSchema
>;

export const FormSchemaNormalQuestionBaseSchema =
  FormSchemaQuestionBaseSchema.merge(
    z.object({
      type: z.literal(FormSchemaQuestionType.QUESTION),
    })
  ).merge(AttributeSchema.pick({ data_type: true }));

export const FormSchemaNormalQuestionNumberSchema =
  FormSchemaNormalQuestionBaseSchema.merge(
    AttributeNumberSchema.omit({ id: true, data_type: true })
  ).merge(
    z.object({
      data_type: z.literal(AttributeDataType.NUMBER),
    })
  );
export type FormSchemaNormalQuestionNumberType = z.infer<
  typeof FormSchemaNormalQuestionNumberSchema
>;

export const FormSchemaNormalQuestionIntegerSchema =
  FormSchemaNormalQuestionBaseSchema.merge(
    AttributeIntegerSchema.omit({ id: true, data_type: true })
  ).merge(
    z.object({
      data_type: z.literal(AttributeDataType.INTEGER),
    })
  );
export type FormSchemaNormalQuestionIntegerType = z.infer<
  typeof FormSchemaNormalQuestionIntegerSchema
>;

export const FormSchemaNormalQuestionLookupSchema =
  FormSchemaNormalQuestionBaseSchema.merge(
    AttributeLookupSchema.omit({ id: true, data_type: true })
  ).merge(
    z.object({
      data_type: z.literal(AttributeDataType.LOOKUP),
      is_linked: z.boolean().optional(),
      parent_facility_question_id: z.string().optional(),
      filter: NodeAPIFilterSchema.optional(),
      lookup_entity_attributes: z.array(z.string()).optional(),
    })
  );
export type FormSchemaNormalQuestionLookupType = z.infer<
  typeof FormSchemaNormalQuestionLookupSchema
>;

export const FormSchemaNormalQuestionPicklistSchema =
  FormSchemaNormalQuestionBaseSchema.merge(
    AttributePicklistSchema.omit({ id: true, data_type: true })
  ).merge(
    z.object({
      data_type: z.literal(AttributeDataType.PICK_LIST),
    })
  );
export type FormSchemaNormalQuestionPicklistType = z.infer<
  typeof FormSchemaNormalQuestionPicklistSchema
>;

export const FormSchemaNormalQuestionMultiPicklistSchema =
  FormSchemaNormalQuestionBaseSchema.merge(
    AttributeMultiPicklistSchema.omit({ id: true, data_type: true })
  ).merge(
    z.object({
      data_type: z.literal(AttributeDataType.MULTI_PICK_LIST),
    })
  );
export type FormSchemaNormalQuestionMultiPicklistType = z.infer<
  typeof FormSchemaNormalQuestionMultiPicklistSchema
>;

// IMPROVE: turn into discriminated union on "data_type" when Zod supports
// nested discriminated unions
export const FormSchemaNormalQuestionSchema =
  FormSchemaNormalQuestionBaseSchema.merge(
    FormSchemaNormalQuestionLookupSchema.omit({ data_type: true })
  ).merge(AttributeSchema.omit({ id: true, data_type: true }));
export type FormSchemaNormalQuestionType = z.infer<
  typeof FormSchemaNormalQuestionSchema
>;

export const FormSchemaMeasurementQuestionSchema = z
  .object({
    type: z.enum([
      FormSchemaQuestionType.MEASUREMENT,
      FormSchemaQuestionType.CALCULATED_MEASUREMENT,
    ]),
    measurement_type: z.nativeEnum(MeasurementTypes),
    // IMPROVE: units are generic for now. better to fold in directly from Node
    // API when their API SDK is ready.
    measurement_unit: z.string(),
    source_lookup_question_id: z.string().optional(),
    subject_lookup_question_id: z.string().optional(),
    measurement_time_question_id: z.string().optional(),
  })
  .merge(FormSchemaQuestionBaseSchema);
export type FormSchemaMeasurementQuestionType = z.infer<
  typeof FormSchemaMeasurementQuestionSchema
>;

export const FormSchemaQuestionSchema = z.discriminatedUnion("type", [
  FormSchemaNormalQuestionSchema,
  FormSchemaMeasurementQuestionSchema,
]);
export type FormSchemaQuestionType = z.infer<typeof FormSchemaQuestionSchema>;

export const EquationComponentField = {
  CONSTANT: "constant",
  OPERATOR: "operator",
  QUESTION: "question",
} as const;

export type EquationComponentFieldType =
  | (typeof EquationComponentField)[keyof typeof EquationComponentField]
  | undefined;

export const EquationComponentFieldSchema = z.union([
  z.literal(EquationComponentField.CONSTANT),
  z.literal(EquationComponentField.OPERATOR),
  z.literal(EquationComponentField.QUESTION),
]);

export type EquationComponentType = {
  id: string;
  value: string;
  fieldType: EquationComponentFieldType;
};

export const EquationComponentSchema = z.object({
  id: z.string(),
  value: z.string(),
  fieldType: EquationComponentFieldSchema,
});

export const FormSchemaQuestionDisplaySchema = z.discriminatedUnion("type", [
  FormSchemaNormalQuestionSchema.extend({
    id: z.string(),
    question_number: z.number(),
  }),
  FormSchemaMeasurementQuestionSchema.extend({
    id: z.string(),
    question_number: z.number(),
    equation_components: z.array(EquationComponentSchema).optional(),
  }),
]);
export type FormSchemaQuestionDisplayType = z.infer<
  typeof FormSchemaQuestionDisplaySchema
>;

export const FormSchemaSectionSchema = z.object({
  id: z.string(),
  questions: z.array(z.string()),
  name: z.string(),
  description: z.string(),
  is_repeatable: z.boolean().optional(),
});
export type FormSchemaSectionType = z.infer<typeof FormSchemaSectionSchema>;

export const FormSchemaSchema = z
  .object({
    name: z.string(),
    form_category_id: z.string(),
    form_submission_editable: z.boolean(),
    form_submission_default_status: z
      .nativeEnum(FormSubmissionStatus)
      .nullable(),
    status: z.nativeEnum(FormSchemaStatus),
    version: z.number().int(),
    company_id: z.string().uuid(),
    description: z.string().optional(),
    form_category: FormCategorySchema,
    config: z.object({
      questions: z.record(FormSchemaQuestionSchema),
      sections: z.array(FormSchemaSectionSchema),
    }),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema)
  .describe("Represents a Form Schema in the Node API");
export type FormSchemaType = z.infer<typeof FormSchemaSchema>;

export const FormSchemaGetListFilterSchema = FormSchemaSchema.pick({
  id: true,
  name: true,
  status: true,
  version: true,
  created_at: true,
  updated_at: true,
})
  .extend({
    "form_category.id": FormSchemaSchema.shape.form_category.shape.id,
    "form_category.name": FormSchemaSchema.shape.form_category.shape.name,
  })
  .partial();
export type FormSchemaGetListFilterType = z.infer<
  typeof FormSchemaGetListFilterSchema
>;

export const isMeasurementFormQuestion = (
  question: FormSchemaQuestionType
): question is FormSchemaMeasurementQuestionType =>
  question.type === "measurement";

export const isNormalFormQuestion = (
  question: FormSchemaQuestionType
): question is FormSchemaNormalQuestionType => question.type === "question";

export const isNormalFormQuestionNumber = (
  question: FormSchemaQuestionType
): question is FormSchemaNormalQuestionNumberType =>
  question.type === "question" && question.data_type === "number";

export const isNormalFormQuestionInteger = (
  question: FormSchemaQuestionType
): question is FormSchemaNormalQuestionIntegerType =>
  question.type === "question" && question.data_type === "integer";

export const isNormalFormQuestionLookup = (
  question: FormSchemaQuestionType
): question is FormSchemaNormalQuestionLookupType =>
  question.type === "question" &&
  question.data_type === "lookup" &&
  typeof question.lookup_entity_type !== "undefined";

export const isNormalFormQuestionPicklist = (
  question: FormSchemaQuestionType
): question is FormSchemaNormalQuestionPicklistType =>
  question.type === "question" &&
  question.data_type === "pick-list" &&
  typeof question.pick_list_values !== "undefined";

export const isNormalFormQuestionMultiPicklist = (
  question: FormSchemaQuestionType
): question is FormSchemaNormalQuestionMultiPicklistType =>
  question.type === "question" &&
  question.data_type === "multi-pick-list" &&
  typeof question.pick_list_values !== "undefined";

export const QuestionValidationRules = {
  MINIMUM: FormSchemaQuestionBaseSchema.keyof().Enum.minimum,
  MAXIMUM: FormSchemaQuestionBaseSchema.keyof().Enum.maximum,
  MIN_LENGTH: FormSchemaQuestionBaseSchema.keyof().Enum.min_length,
  MAX_LENGTH: FormSchemaQuestionBaseSchema.keyof().Enum.max_length,
  BEFORE: FormSchemaQuestionBaseSchema.keyof().Enum.before,
  AFTER: FormSchemaQuestionBaseSchema.keyof().Enum.after,
} as const;

export type QuestionValidationRulesType =
  | (typeof QuestionValidationRules)[keyof typeof QuestionValidationRules]
  | undefined;
