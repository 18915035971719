import { LookupField } from "#src/batteries-included-components/Forms/FormSubmissionForm/FormSubmissionSections/FormSubmissionField/QuestionField/LookupField";
import {
  DropdownInput,
  RadioInput,
  TextAreaInput,
  TextInput,
  useFormContext,
} from "@validereinc/common-components";
import { AttributeDataType, FormSchemaQuestionType } from "@validereinc/domain";
import React from "react";

const DEFAULT_ANSWER_LABEL = "Default Answer";
const DEFAULT_ANSWER_NAME = "default_answer";

export const QuestionDefaultAnswer = () => {
  const form = useFormContext();

  const questionType = form.watch("type");
  const dataType = form.watch("data_type");
  const isLongString = form.watch("is_long_string");
  const lookupEntityType = form.watch("lookup_entity_type");
  const pickListValues = form.watch("pick_list_values");

  return (
    <>
      {dataType === AttributeDataType.STRING ? (
        isLongString ? (
          <TextAreaInput
            label={DEFAULT_ANSWER_LABEL}
            name={DEFAULT_ANSWER_NAME}
            isRequired
            isFluid
          />
        ) : (
          <TextInput
            label={DEFAULT_ANSWER_LABEL}
            name={DEFAULT_ANSWER_NAME}
            isRequired
            isFluid
          />
        )
      ) : null}

      {[AttributeDataType.NUMBER, AttributeDataType.INTEGER].includes(
        dataType
      ) || questionType === FormSchemaQuestionType.MEASUREMENT ? (
        <TextInput
          label={DEFAULT_ANSWER_LABEL}
          name={DEFAULT_ANSWER_NAME}
          type="number"
          formatType={dataType ?? "number"}
          isRequired
          isFluid
        />
      ) : null}

      {dataType === AttributeDataType.BOOLEAN ? (
        <RadioInput
          label={DEFAULT_ANSWER_LABEL}
          name={DEFAULT_ANSWER_NAME}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
          valueKey="value"
          labelKey="label"
          isRequired
          isFluid
        />
      ) : null}

      {[
        AttributeDataType.PICK_LIST,
        AttributeDataType.MULTI_PICK_LIST,
      ].includes(dataType) ? (
        <DropdownInput
          label={DEFAULT_ANSWER_LABEL}
          name={DEFAULT_ANSWER_NAME}
          options={pickListValues}
          valueKey="value"
          labelKey="value"
          isMulti={dataType === AttributeDataType.MULTI_PICK_LIST}
          isRequired
          isFluid
        />
      ) : null}

      {dataType === AttributeDataType.LOOKUP ? (
        <LookupField
          label={DEFAULT_ANSWER_LABEL}
          name={DEFAULT_ANSWER_NAME}
          lookup_entity_type={lookupEntityType}
          isRequired
          isFluid
        />
      ) : null}

      {dataType === AttributeDataType.FILE ? (
        <>
          <TextInput
            label="Default File Reference ID"
            name={`${DEFAULT_ANSWER_NAME}.ref`}
            isRequired
            isFluid
          />
          <TextInput
            label="Default File Name"
            name={`${DEFAULT_ANSWER_NAME}.name`}
            description="File name must include file extension."
            isRequired
            isFluid
          />
        </>
      ) : null}
    </>
  );
};
