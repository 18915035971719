import React from "react";

import { UserPermissionsTablePanel } from "#src/batteries-included-components/Panels";

import classNames from "classnames/bind";
import styles from "./UserPermissionsTab.module.scss";
const cx = classNames.bind(styles);

export const UserPermissionsTab = () => {
  return (
    <div className={cx("container")}>
      <div className={cx("contentContainer")}>
        <UserPermissionsTablePanel />
      </div>
    </div>
  );
};
