import { NetworkCalculationResultRoute } from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details";
import { NetworkCalculationResultAdjustmentPanel } from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details/NetworkCalculationResultAdjustmentPanel";
import { NetworkCalculationResultDetailPanel } from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details/NetworkCalculationResultDetailPanel";
import {
  CalculationFlowVariant,
  NetworkCalculationResultFlowPanel,
} from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details/NetworkCalculationResultFlowPanel";
import { NetworkCalculationResultFormulaPanel } from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details/NetworkCalculationResultFormulaPanel";
import { useActionDetails } from "#src/components/hooks/useActionDetails";
import { useNetworkCalculationResult } from "#src/routes/organization/networks/[networkId]/detail/calculation-result/[period]/details/NetworkCalculationResultPage.helpers";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { Column, Grid, Page, Row } from "@validereinc/common-components";
import { DateFormats, monthFormatter } from "@validereinc/utilities";
import parse from "date-fns/parse";
import React, { useRef } from "react";
import { useParams } from "react-router";

export const NetworkCalculationResultPage = () => {
  const { period } =
    useParams<
      Exclude<typeof NetworkCalculationResultRoute.pathParams, undefined>
    >();
  const resultPeriod = useRef(
    parse(period ?? "", DateFormats.YEAR_MONTH, new Date())
  );
  const { network, run, isLoading } = useNetworkCalculationResult();
  const calculatedInfo = useActionDetails({
    type: "run",
    date: run?.updated_at,
    byUserId: run?.updated_by,
  });
  const [breadcrumbs] = useBreadcrumbsFromRoute(NetworkCalculationResultRoute, {
    "/details": {
      title: resultPeriod.current
        ? `${NetworkCalculationResultRoute.title} for ${monthFormatter(resultPeriod.current)}`
        : NetworkCalculationResultRoute.title,
    },
    "/detail": {
      title: network?.name ?? "",
    },
  });

  return (
    <Page
      category={NetworkCalculationResultRoute.previous?.title}
      title={
        monthFormatter(resultPeriod.current) ??
        NetworkCalculationResultRoute.title
      }
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
      renderMeta={
        !calculatedInfo.description
          ? undefined
          : ({ MetaSegments }) => (
              <MetaSegments values={[calculatedInfo.description]} />
            )
      }
    >
      <Grid>
        <Row>
          <Column variant={6}>
            <NetworkCalculationResultDetailPanel />
          </Column>
          <Column variant={18}>
            <NetworkCalculationResultAdjustmentPanel />
            <NetworkCalculationResultFlowPanel
              variant={CalculationFlowVariant.INLET}
            />
            <NetworkCalculationResultFlowPanel
              variant={CalculationFlowVariant.NON_ADJUSTABLE}
            />
            <NetworkCalculationResultFlowPanel
              variant={CalculationFlowVariant.ADJUSTABLE}
            />
            <NetworkCalculationResultFormulaPanel />
          </Column>
        </Row>
      </Grid>
    </Page>
  );
};
