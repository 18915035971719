import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { AddUserGroupMembershipDialog } from "#src/batteries-included-components/Dialogs/AddUserGroupMembershipDialog";
import { DeleteUserGroupMembershipDialog } from "#src/batteries-included-components/Dialogs/DeleteUserGroupMembershipDialog";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useListUserGroups } from "#src/components/hooks/adapters/useUserGroups";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { UserDetailContext } from "#src/routes/settings/users/detail/UserDetailContext";
import { UserGroupDetailsRoutePath } from "#src/routes/settings/users/groups/[groupId]/details";
import {
  Button,
  DataTable,
  DataTablePanel,
  HeaderType,
} from "@validereinc/common-components";
import {
  SortDirection,
  UserGroupSchema,
  UserGroupType,
} from "@validereinc/domain";
import React, { useContext, useState } from "react";

export const UserGroupMembershipTablePanel = () => {
  const [tableState, updateTableState] = useTableSortingAndPagination({
    sortBy: "name",
    sortDirection: SortDirection.ASCENDING,
  });
  const [isAddUserUserGroupOpen, setIsAddUserUserGroupOpen] =
    useState<boolean>();
  const [groupToDeleteFrom, setItemToRemove] = useState<UserGroupType | null>();
  const [isUserGroupWriteEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:roles_permissions",
    permissionQuery: "user_groups:write",
  });
  const [isUserGroupDeleteEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:roles_permissions",
    permissionQuery: "user_groups:delete",
  });
  const { userDetails } = useContext(UserDetailContext) ?? {};

  const userId = userDetails?.data?.id;
  const isUserIdDefined = !!userId;

  const params = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: { "user.id": [userId] },
  };

  const { data, isLoading } = useListUserGroups(params, {
    enabled: isUserIdDefined,
  });

  const headers: Array<HeaderType<UserGroupType>> = [
    {
      key: UserGroupSchema.keyof().Enum.name,
      label: "Name",
      isSortable: true,
      renderComponent: ({ item }) => {
        return (
          <RoutingLink
            to={UserGroupDetailsRoutePath.toLink({
              pathParams: { groupId: item.id },
            })}
          >
            {item.name}
          </RoutingLink>
        );
      },
    },
    {
      key: UserGroupSchema.keyof().Enum.description,
      label: "Description",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.TextCell value={item.description} />
      ),
    },
  ];

  const getItemActions = ({ item }: { item: UserGroupType }) => [
    {
      label: "Remove",
      buttonProps: {
        onClick: () => setItemToRemove(item),
        icon: "minus-circle",
      },
    },
  ];

  const actionRow = [
    <Button
      key="add-user-user-group"
      onClick={() => setIsAddUserUserGroupOpen(true)}
    >
      Add
    </Button>,
  ];

  const items = data?.data ?? [];

  return (
    <>
      <DataTablePanel
        panelProps={{ title: "User Groups", actionRow, isFluidY: false }}
        dataTableProps={{
          items,
          isLoading,
          headers,
          getItemActions,
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
          sorting: {
            sortBy: tableState.sortBy,
            sortDirection: tableState.sortDirection,
          },
          pagination: {
            page: tableState.page,
            pageSize: tableState.pageSize,
            total: data?.total_entries,
          },
        }}
      />
      {isUserGroupWriteEnabled && userDetails?.data ? (
        <AddUserGroupMembershipDialog
          isOpen={!!isAddUserUserGroupOpen}
          onClose={() => setIsAddUserUserGroupOpen(false)}
          user={userDetails.data}
        />
      ) : null}
      {isUserGroupDeleteEnabled && userDetails?.data && groupToDeleteFrom ? (
        <DeleteUserGroupMembershipDialog
          isOpen={!!groupToDeleteFrom}
          onClose={() => setItemToRemove(null)}
          groupToDeleteFrom={groupToDeleteFrom}
          user={userDetails.data}
        />
      ) : null}
    </>
  );
};
