import { z } from "zod";
import { Resources } from "../util";
import { AssetType } from "./AssetSchemas";
import {
  AttributeBaseSchema,
  AttributePicklistSchema,
} from "./AttributeSchemas";
import { DomainModelMetaSchema } from "./DomainModelSchemas";

const CustomAttributeRecordValueSchema = z.union([
  z.string(),
  z.number(),
  z.array(z.string()),
  z.array(z.number()),
  z.boolean(),
]);
export const CustomAttributeRecordSchema = z.record(
  CustomAttributeRecordValueSchema
);

export const CustomAttributeSchema = z
  .object({
    archived: z.boolean(),
    company_id: z.string(),
    display_name: z.string(),
    entity_type: z.enum([
      AssetType.EQUIPMENT,
      AssetType.DEVICE,
      AssetType.FACILITY,
      AssetType.FLOW,
      AssetType.ASSET_GROUP,
    ]),
    lookup_entity_type: z
      .enum([
        AssetType.EQUIPMENT,
        AssetType.DEVICE,
        AssetType.FACILITY,
        AssetType.FLOW,
        AssetType.ASSET_GROUP,
        Resources.FORM_SCHEMA,
      ])
      .optional(),
    entity_subtypes: z.array(z.string()).optional(),
    field_name: z.string(),
    unit: z.string().optional(),
  })
  .merge(
    AttributeBaseSchema.pick({
      data_type: true,
      description: true,
      is_required: true,
    })
  )
  .merge(
    AttributePicklistSchema.pick({
      pick_list_values: true,
    })
  )
  .merge(DomainModelMetaSchema)
  .describe("Schema for user-defined custom attributes on assets");

export type CustomAttributeType = z.infer<typeof CustomAttributeSchema>;
export type CustomAttributeRecordValueType = z.infer<
  typeof CustomAttributeRecordValueSchema
>;
export type CustomAttributeRecordType = z.infer<
  typeof CustomAttributeRecordSchema
>;
