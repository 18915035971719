import React from "react";

import classNames from "classnames/bind";

import { RoleDetailsPanel } from "#src/batteries-included-components/Panels";

import styles from "./RoleSummaryTab.module.scss";

const cx = classNames.bind(styles);

export const RoleSummaryTab = () => {
  return (
    <div className={cx("container")}>
      <div className={cx("contentContainer")}>
        <RoleDetailsPanel />
      </div>
    </div>
  );
};
