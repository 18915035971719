import { RecordFacilitySummaryPanel } from "#batteries-included-components/Panels/SummaryPanels/RecordFacilitySummaryPanel";
import {
  FACILITIES_BREADCRUMB,
  FACILITY_DETAIL_BREADCRUMB,
} from "#routers/breadcrumbs";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useParams } from "#routers/hooks";
import { ORGANIZATION_BREADCRUMB } from "#routes/organization";
import { RecordValueTablePanel } from "#src/batteries-included-components/Panels/TablePanels/RecordValueTablePanel";
import { useActionDetails } from "#src/components/hooks/useActionDetails";
import {
  FacilityContext,
  FacilityProvider,
} from "#src/contexts/FacilityContext";
import { RecordContext, RecordProvider } from "#src/contexts/RecordContext";
import useLocalization from "#src/hooks/useLocalization";
import { Page, Pill, PillProps } from "@validereinc/common-components";
import { getRecordStatusFromValues } from "@validereinc/domain";
import { yearMonthName } from "@validereinc/utilities";
import React, { useContext } from "react";
import {
  FACILITY_RECORD_DETAIL_BREADCRUMB,
  FACILITY_RECORD_DETAIL_PAGE_TITLE,
  type FacilityRecordDetailPageParamsType,
} from "./index";

const FlowRecordDetailPageContent = () => {
  const params = useParams<FacilityRecordDetailPageParamsType>();
  const { localize } = useLocalization();

  const { facility } = useContext(FacilityContext) || {};
  const { record } = useContext(RecordContext) || {};

  const pageTitle = record
    ? `${yearMonthName(record.year_month)} Record`
    : FACILITY_RECORD_DETAIL_PAGE_TITLE;

  const editInfo = useActionDetails({
    type: "edit",
    date: record?.updated_at,
    byUserId: record?.updated_by,
  });

  const breadcrumbs = useBreadcrumbs(
    [
      ORGANIZATION_BREADCRUMB,
      FACILITIES_BREADCRUMB,
      FACILITY_DETAIL_BREADCRUMB,
      FACILITY_RECORD_DETAIL_BREADCRUMB,
    ],
    {
      2: facility?.name,
      3: pageTitle,
    },
    {
      ...params,
      id: params.facilityId,
    }
  );

  const lockStatus = record?.values
    ? getRecordStatusFromValues(record.values)
    : "";

  const recordStatusOptions: Record<
    string,
    { label: string; variant: PillProps["variant"] }
  > = {
    locked: { label: "Locked", variant: "default" },
    partially_locked: { label: "Partially Locked", variant: "info" },
    unlocked: { label: "Unlocked", variant: "info" },
  };

  return (
    <Page
      category={`${localize("Facility")} Record`}
      title={
        !record ? (
          pageTitle
        ) : (
          <>
            {pageTitle}&nbsp;
            <Pill
              variant={
                lockStatus ? recordStatusOptions[lockStatus].variant : "default"
              }
              hasDot={false}
            >
              {lockStatus ? recordStatusOptions[lockStatus].label : ""}
            </Pill>
          </>
        )
      }
      breadcrumbs={breadcrumbs}
      renderMeta={
        !editInfo.description
          ? undefined
          : ({ MetaSegments }) => (
              <MetaSegments values={[editInfo.description]} />
            )
      }
    >
      <RecordFacilitySummaryPanel />
      <RecordValueTablePanel />
    </Page>
  );
};

export const FacilityRecordDetailPage = () => (
  <FacilityProvider>
    <RecordProvider>
      <FlowRecordDetailPageContent />
    </RecordProvider>
  </FacilityProvider>
);
