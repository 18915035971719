import {
  DeviceDropdownInput,
  EquipmentDropdownInput,
  FacilityDropdownInput,
  FlowDropdownInput,
} from "#src/batteries-included-components";
import { AssetGroupDropdownInput } from "#src/batteries-included-components/Dropdowns/AssetGroupDropdownInput";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { useQuery } from "@tanstack/react-query";
import {
  DateSelectorInput,
  DropdownInput,
  GeoPointInput,
  RadioInput,
  TextInput,
} from "@validereinc/common-components";
import {
  AssetType,
  AttributeDataType,
  FormSchemaAdapter,
  FormSchemaMeasurementQuestionType,
  FormSchemaNormalQuestionType,
  FormSchemaQuestionType,
  FormSchemaSectionType,
} from "@validereinc/domain";
import React, { useMemo } from "react";

export const useFormSubmissionAnswerFilters = (formSchemaId: string) => {
  const templateQuery = useQuery({
    queryKey: ["formSchemas", formSchemaId],
    queryFn: async ({ queryKey }) => {
      const [_, formSchemaId] = queryKey;

      return await FormSchemaAdapter.getOne({
        id: formSchemaId,
      });
    },
    staleTime: 2 * 60 * 1000,
  });
  const { measurementUnits } = useMeasurementTypes();

  const getInputComponentForQuestion = (
    questionId: string,
    question: FormSchemaMeasurementQuestionType | FormSchemaNormalQuestionType,
    section: FormSchemaSectionType
  ): React.ReactNode => {
    const name = `${section.id}:${questionId}`;
    const label = question.prompt;
    const description = question.description;

    switch (question.type) {
      case FormSchemaQuestionType.MEASUREMENT: {
        const displayMeasurementUnit = measurementUnits.find(
          ({ id }) => question.measurement_unit === id
        );

        return (
          <TextInput
            name={name}
            label={label}
            description={description}
            type="number"
            unit={displayMeasurementUnit?.name.symbol}
          />
        );
      }
      case FormSchemaQuestionType.QUESTION: {
        switch (question.data_type) {
          case AttributeDataType.STRING:
            return (
              <TextInput
                name={name}
                label={label}
                description={description}
                isOptionalTextShown={false}
              />
            );
          case AttributeDataType.NUMBER:
            return (
              <TextInput
                name={name}
                label={label}
                description={description}
                type="number"
                isOptionalTextShown={false}
              />
            );
          case AttributeDataType.INTEGER:
            return (
              <TextInput
                name={name}
                label={label}
                description={description}
                type="number"
                step={1}
                isOptionalTextShown={false}
              />
            );
          case AttributeDataType.DATE:
            return (
              <DateSelectorInput
                name={name}
                label={label}
                description={description}
                isFluid
                isRange={false}
                isOptionalTextShown={false}
              />
            );
          case AttributeDataType.DATE_TIME:
            return (
              <DateSelectorInput
                name={name}
                label={label}
                description={description}
                variant="time"
                isFluid
                isRange={false}
                isOptionalTextShown={false}
              />
            );
          case AttributeDataType.LOOKUP: {
            switch (question.lookup_entity_type) {
              case AssetType.FACILITY:
                return (
                  <FacilityDropdownInput
                    name={name}
                    label={label}
                    description={description}
                    isOptionalTextShown={false}
                  />
                );
              case AssetType.EQUIPMENT:
                return (
                  <EquipmentDropdownInput
                    name={name}
                    label={label}
                    description={description}
                  />
                );
              case AssetType.DEVICE:
                return (
                  <DeviceDropdownInput
                    name={name}
                    label={label}
                    description={description}
                  />
                );
              case AssetType.FLOW:
                return (
                  <FlowDropdownInput
                    name={name}
                    label={label}
                    description={description}
                  />
                );
              case AssetType.ASSET_GROUP:
                return (
                  <AssetGroupDropdownInput
                    name={name}
                    label={label}
                    description={description}
                  />
                );
              default:
                return null;
            }
          }
          case AttributeDataType.PICK_LIST:
          case AttributeDataType.MULTI_PICK_LIST:
            return (
              <DropdownInput
                name={name}
                label={label}
                description={description}
                options={question.pick_list_values}
                labelKey="name"
                valueKey="id"
                isMulti={
                  question.data_type === AttributeDataType.MULTI_PICK_LIST
                }
                isFluid
                isOptionalTextShown={false}
              />
            );
          case AttributeDataType.GEO_POINT:
            return (
              <GeoPointInput
                name={name}
                label={label}
                description={description}
                isFluid
                isOptionalTextShown={false}
              />
            );
          case AttributeDataType.BOOLEAN:
            return (
              <RadioInput
                name={name}
                label={label}
                description={description}
                labelKey="label"
                valueKey="value"
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
                isOptionalTextShown={false}
              />
            );
        }
      }
    }

    return null;
  };

  const filters = useMemo(() => {
    if (!templateQuery.data?.data) return [];

    return templateQuery.data.data.config?.sections.map((sectionId) => {
      return {
        section: sectionId.name,
        questions: sectionId.questions
          .map((questionId) =>
            getInputComponentForQuestion(
              questionId,
              templateQuery.data?.data.config.questions[questionId],
              sectionId
            )
          )
          .filter((config) => !!config),
      };
    });
  }, [templateQuery.data?.data]);

  return filters;
};
