import { ResourceDefinitions, ResourceDefinitionType } from "./resources";

/**
 * Every permission available in the system maps to a specific Resource (i.e.
 * Feature) in the app
 */
export const PermissionsToResourceMapping: Record<
  `${string}:*`,
  ResourceDefinitionType
> = {
  "workflows:*": ResourceDefinitions.workflow,
  "workflow_templates:*": ResourceDefinitions.workflow_template,
  "workflow_tasks:*": ResourceDefinitions.workflow_task,
  "records:*": ResourceDefinitions.record,
  "networks:*": ResourceDefinitions.network,
  "measurements:*": ResourceDefinitions.measurement,
  "form_categories:*": ResourceDefinitions.form_category,
  "form_submissions:*": ResourceDefinitions.form_submission,
  "form_schemas:*": ResourceDefinitions.form_schema,
  "facilities:*": ResourceDefinitions.facility,
  "equipment:*": ResourceDefinitions.equipment,
  "devices:*": ResourceDefinitions.device,
  "flows:*": ResourceDefinitions.flow,
  "events:*": ResourceDefinitions.event,
  "event_schemas:*": ResourceDefinitions.event_schema,
  "estimation_methods:*": ResourceDefinitions.estimation_method,
  "calculator_results:*": ResourceDefinitions.calculator_result,
  "dashboards:*": ResourceDefinitions.dashboard,
  "asset_groups:*": ResourceDefinitions.asset_group,
  "activities:*": ResourceDefinitions.activity,
  "reporting_groups:*": ResourceDefinitions.reporting_group,
  "company_custom_reports:*": ResourceDefinitions.company_custom_reports,
  "data_submissions:*": ResourceDefinitions.transaction,
  "insights_dashboards:*": ResourceDefinitions.dashboards_insights,
  "emissions:*": ResourceDefinitions.emissions,
  "reports:*": ResourceDefinitions.report,
  "ledger:*": ResourceDefinitions.ledger,
  "jobs:*": ResourceDefinitions.job,
  "company_unit_config:*": ResourceDefinitions.company_unit_config,
  "custom_attribute_definition:*":
    ResourceDefinitions.custom_attribute_definition,
  "roles:*": ResourceDefinitions.role,
  "templated_configuration_runs:*":
    ResourceDefinitions.templated_configuration_run,
  "users:*": ResourceDefinitions.user,
  "user_groups:*": ResourceDefinitions.user_group,
  "user_group_categories:*": ResourceDefinitions.user_group_category,
  "volumetric_reporting:*": ResourceDefinitions.volumetric_reporting,
  "comments:*": ResourceDefinitions.comment,
};
