import { useBreadcrumbs } from "#src/Routers/breadcrumbsHelper";
import { useNavigate, useParams, useSearchParams } from "#src/Routers/hooks";
import { UpdateFormTemplateStatusDialog } from "#src/batteries-included-components/Dialogs/UpdateFormTemplateStatusDialog";
import { FormSubmissionsViewFilterArea } from "#src/batteries-included-components/FilterAreas/FormsFilterAreas";
import { FormSubmissionsTablePanel } from "#src/batteries-included-components/Panels/TablePanels/FormSubmissionsTablePanel";
import { StatusVariants } from "#src/components/Common/SummaryInformation";
import { useActionDetails } from "#src/components/hooks/useActionDetails";
import {
  useHasPermission,
  useIsFeatureAvailable,
} from "#src/contexts/AuthenticatedContext.helpers";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { FORMS_BREADCRUMB } from "#src/routes/forms";
import { FORMS_CATEGORIES_BREADCRUMB } from "#src/routes/forms/categories";
import {
  FORM_CATEGORY_DETAILS_BREADCRUMB,
  linkToFormCategoryDetails,
} from "#src/routes/forms/categories/[categoryId]";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  ButtonToggleGroup,
  KeyValuePanel,
  Link,
  Page,
  Pill,
} from "@validereinc/common-components";
import {
  FormCategoryAdapter,
  FormSchemaAdapter,
  FormSchemaType,
  Resources,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useEffect, useState } from "react";
import { FORM_TEMPLATE_DETAILS_BREADCRUMB } from ".";
import { linkToEditFormCategoryFormTemplate } from "../../edit-form-template/[formSchemaId]";
import styles from "./FormTemplateDetailPage.module.scss";
import { linkToCreateFormSubmission } from "./create-form-submission";

const cx = classNames.bind(styles);

export const FormTemplateDetailsPage = () => {
  const [canReadFormSchemas] = useHasPermission("form_schemas:read");
  const [canWriteFormSchemas] = useHasPermission("form_schemas:write");
  const [isEditFormTemplateButtonAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "forms:schemas_create",
  });

  const { categoryId, formTemplateId } = useParams<{
    categoryId: string;
    formTemplateId: string;
  }>();

  const [{ "submission-type": submissionsType }, setSearchParams] =
    useSearchParams();

  const [updateStatus, setUpdateStatus] = useState<
    "active" | "archived" | "draft" | undefined
  >(undefined);

  const setSubmissionType = (newSubmissionType: string) => {
    setSearchParams({ "submission-type": newSubmissionType });
  };

  useEffect(() => {
    if (!submissionsType) {
      setSubmissionType("all");
    }
  }, [submissionsType]);

  const {
    filterConfigStorageKey,
    tableConfigStorageKey,
    viewConfigStorageKey,
  } = useStorageKey("form-template-form-submissions");

  const navigate = useNavigate();

  const categoryQuery = useQuery({
    queryKey: [Resources.FORM_CATEGORY, categoryId],
    queryFn: async ({ queryKey }) => {
      const [_, id] = queryKey;

      return await FormCategoryAdapter.getOne({
        id,
      });
    },
  });

  const templateQuery = useQuery({
    queryKey: [Resources.FORM_SCHEMA, formTemplateId],
    queryFn: async ({ queryKey }) => {
      const [_, formSchemaId] = queryKey;

      return await FormSchemaAdapter.getOne({
        id: formSchemaId,
      });
    },
    staleTime: 2 * 60 * 1000,
    select: (resp) => resp?.data,
  });

  const createInformation = useActionDetails({
    type: "create",
    date: templateQuery.data?.created_at,
    byUserId: templateQuery.data?.created_by,
  });
  const editInformation = useActionDetails({
    type: "edit",
    date: templateQuery.data?.updated_at,
    byUserId: templateQuery.data?.updated_by,
  });

  const breadcrumbs = useBreadcrumbs(
    [
      FORMS_BREADCRUMB,
      FORMS_CATEGORIES_BREADCRUMB,
      FORM_CATEGORY_DETAILS_BREADCRUMB,
      FORM_TEMPLATE_DETAILS_BREADCRUMB,
    ],
    {
      2: categoryQuery?.data?.name,
      3: templateQuery?.data?.name,
    }
  );

  const getStatusPillVariant = (status: FormSchemaType["status"]) => {
    switch (status) {
      case "active":
        return StatusVariants.ACTIVE;
      case "draft":
        return StatusVariants.INFO;
      case "archived":
      default:
        return StatusVariants.INACTIVE;
    }
  };

  return (
    <Page
      title={
        templateQuery?.data ? (
          <>
            {templateQuery.data.name}&nbsp;
            <Pill
              variant={getStatusPillVariant(templateQuery.data.status)}
              hasDot={false}
            >
              {templateQuery.data.status}
            </Pill>
          </>
        ) : (
          ""
        )
      }
      category="Form Template"
      breadcrumbs={breadcrumbs}
      actionRow={[
        ...(templateQuery?.data?.status === "active"
          ? [
              <Button
                key="archive-action"
                variant="error-outline"
                onClick={() => setUpdateStatus("archived")}
              >
                Archive
              </Button>,
              canReadFormSchemas &&
                canWriteFormSchemas &&
                isEditFormTemplateButtonAvailable && (
                  <Button
                    key="edit-action"
                    onClick={() =>
                      navigate({
                        pathname: linkToEditFormCategoryFormTemplate(
                          categoryId,
                          formTemplateId
                        ),
                      })
                    }
                  >
                    Edit
                  </Button>
                ),
              <Button
                key="create-submission-action"
                onClick={() => {
                  navigate({
                    pathname: linkToCreateFormSubmission(
                      categoryId,
                      formTemplateId
                    ),
                  });
                }}
                variant="primary"
              >
                Create Submission
              </Button>,
            ]
          : []),
        ...(templateQuery?.data?.status === "archived"
          ? [
              <Button
                key="activate-button"
                onClick={() => setUpdateStatus("active")}
              >
                Activate
              </Button>,
            ]
          : []),
      ]}
      renderMeta={
        !editInformation.description
          ? undefined
          : ({ MetaSegments }) => (
              <MetaSegments values={[editInformation.description]} />
            )
      }
    >
      <div className={cx("topContainer")}>
        <KeyValuePanel
          panelMaxColumnCount={2}
          panelKeyValueListContainerProps={{
            style: { padding: 0 },
          }}
          panelProps={{
            title: "Template Details",
            isFluidY: true,
            loaded: !templateQuery.isLoading,
          }}
          data={[
            {
              title: "Created Date",
              value: createInformation.formattedDate,
            },
            {
              title: "Created By",
              value: createInformation.userName,
            },
            {
              title: "Category",
              value: (
                <Link
                  onClick={() => {
                    navigate({
                      pathname: linkToFormCategoryDetails(
                        categoryQuery?.data?.id
                      ),
                    });
                  }}
                  label={categoryQuery?.data?.name ?? "-"}
                />
              ),
            },
            {
              title: "Description",
              value: templateQuery?.data?.description
                ? templateQuery?.data.description.trim()
                : "-",
            },
            {
              title: "Current Version",
              value: (
                <div className={cx("versionPill")}>
                  <Pill variant="default">{templateQuery?.data?.version}</Pill>
                </div>
              ),
            },
          ]}
        />
      </div>

      <div className={cx("filterContainer")}>
        <ButtonToggleGroup
          items={[
            { label: "Submissions", dataKey: "all" },
            { label: "Drafts", dataKey: "draft" },
          ]}
          activeKey={submissionsType}
          onChange={(newStatus: "all" | "draft") =>
            setSubmissionType(newStatus)
          }
        />
        <FormSubmissionsViewFilterArea
          viewConfigStorageKey={viewConfigStorageKey}
        />
      </div>

      <FormSubmissionsTablePanel
        formSchemaId={formTemplateId}
        isDraft={submissionsType === "draft"}
        tableConfigStorageKey={`${tableConfigStorageKey}-${formTemplateId}`}
        filterConfigStorageKey={`${filterConfigStorageKey}-${formTemplateId}`}
        viewConfigStorageKey={viewConfigStorageKey}
      />

      <UpdateFormTemplateStatusDialog
        isOpen={!!updateStatus}
        onClose={() => setUpdateStatus(undefined)}
        formSchemaId={formTemplateId}
        status={updateStatus}
      />
    </Page>
  );
};
