import { useParams, useSearchParams } from "#routers/hooks";
import WorkflowCommentsDrawer from "#src/batteries-included-components/Drawers/Workflows/WorkflowCommentsDrawer/WorkflowCommentsDrawer";
import { WorkflowFormSubmissionsTablePanel } from "#src/batteries-included-components/Panels/TablePanels/WorkflowFormSubmissionsTablePanel";
import { WorkflowStatusToPillVariantMap } from "#src/batteries-included-components/Panels/TablePanels/WorkflowsTablePanel/WorkflowsTablePanel.helpers";
import { WorkflowTasksTablePanel } from "#src/batteries-included-components/Panels/TablePanels/WorkflowTasksTablePanel";
import { useGetOneFormSubmission } from "#src/components/hooks/adapters/useFormSubmissions";
import { useGetOneWorkflow } from "#src/components/hooks/adapters/useWorkflows";
import { useActionDetails } from "#src/components/hooks/useActionDetails";
import {
  useCheckCommentPermissions,
  useCommentsStore,
} from "#src/components/hooks/useComments";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import {
  Button,
  ButtonToggleGroup,
  Icon,
  Page,
  Pill,
  TimelinePanel,
  useTimelinePanelStore,
} from "@validereinc/common-components";
import { toStartCaseString } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React, { useEffect, useMemo, useState } from "react";
import { WorkflowDetailsRoutePath } from ".";
import { WorkflowAnsersKeyValuePanel } from "./WorkflowAnswersKeyValuePanel";
import { WorkflowDetailDeleteDialog } from "./WorkflowDetailDeleteDialog";
import { WorkflowDetailKeyValuePanel } from "./WorkflowDetailKeyValuePanel";
import styles from "./WorkflowDetailPage.module.scss";
import { WorkflowDetailStatusDialog } from "./WorkflowDetailStatusDialog";

const cx = classNames.bind(styles);

export const WorkflowDetailPage = () => {
  const { workflowId } = useParams<{
    workflowId: string;
  }>();
  const tasksStorageKeys = useStorageKey("workflows-detail-tasks");
  const formsStorageKeys = useStorageKey("workflows-detail-forms");
  const {
    isOpen: isWorkflowCommentsDrawerOpen,
    setIsOpen: setIsWorkflowCommentsDrawerOpen,
  } = useTimelinePanelStore();
  const { commentCounts } = useCommentsStore();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);

  const [{ context }, setSearchParams] = useSearchParams<{
    context: "actions" | "submissions";
  }>();

  useEffect(() => {
    if (context) return;
    setSearchParams({ context: "actions" });
  }, [context]);

  const workflowQuery = useGetOneWorkflow(
    { id: workflowId },
    { enabled: Boolean(workflowId) }
  );
  const workflow = useMemo(() => workflowQuery.data?.data, [workflowQuery]);
  const { canReadComments, canReadAssetType } = useCheckCommentPermissions(
    workflow?.asset?.asset_type
  );
  const { workflow_template } = workflow ?? {};

  const workflowIsLoading = workflowQuery.isLoading;

  const workflowWithTrigger = { ...workflow };

  const createInfo = useActionDetails({
    type: "create",
    date: workflow?.created_at,
  });
  const updateInfo = useActionDetails({
    type: "update",
    date: workflow?.updated_at,
    byUserId: workflow?.updated_by,
  });
  const dueDateInfo = useActionDetails({
    type: "due",
    date: workflow?.due_date,
  });
  const metaSegments = [
    dueDateInfo.description,
    updateInfo.description,
    createInfo.description,
  ].filter((s) => s !== undefined);

  const [breadcrumbs] = useBreadcrumbsFromRoute(WorkflowDetailsRoutePath, {
    "/detail": {
      title: `${workflow?.name} - ${workflow?.id}`,
    },
  });

  const formSubmissionId = workflow?.form_trigger?.form_submission_id;
  const { data: formSubmission, isLoading: formSubmissionIsLoading } =
    useGetOneFormSubmission(
      { id: formSubmissionId },
      {
        enabled: !!formSubmissionId,
      }
    );

  const triggeringWorkflowId =
    workflowWithTrigger.workflow_trigger?.triggering_workflow_id ?? "";
  const triggeringWorkflowQuery = useGetOneWorkflow(
    { id: triggeringWorkflowId },
    { enabled: Boolean(triggeringWorkflowId) }
  );
  const triggeringWorkflow = triggeringWorkflowQuery.data?.data;
  const triggeringWorkflowIsLoading = triggeringWorkflowQuery.isLoading;

  if (formSubmission) {
    workflowWithTrigger.form_trigger.form_submission = formSubmission;
  }
  if (workflowWithTrigger.workflow_trigger && triggeringWorkflow) {
    workflowWithTrigger.workflow_trigger.triggering_workflow_name =
      triggeringWorkflow.name;
  }

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleChangeStatus = () => {
    setIsStatusModalOpen(true);
  };

  useEffect(() => {
    setIsWorkflowCommentsDrawerOpen(false);
  }, []);

  const commentCount = commentCounts[`workflowComments-${workflowId}`] || 0;

  return (
    <>
      <Page
        title={
          workflow ? (
            <>
              {workflow.name}&nbsp;
              <Pill
                variant={
                  WorkflowStatusToPillVariantMap[workflow.status] ?? "default"
                }
                hasDot={false}
              >
                {toStartCaseString(workflow.status)}
              </Pill>
            </>
          ) : (
            WorkflowDetailsRoutePath.title
          )
        }
        category={WorkflowDetailsRoutePath.subtitle}
        breadcrumbs={breadcrumbs}
        isLoading={
          workflowIsLoading ||
          (!!formSubmissionId && formSubmissionIsLoading) ||
          (!!triggeringWorkflowId && triggeringWorkflowIsLoading)
        }
        actionRow={[
          <Button
            key="secondary"
            variant="error-outline"
            onClick={handleDelete}
          >
            Delete
          </Button>,
          ...(canReadComments && canReadAssetType
            ? [
                <TimelinePanel.Trigger
                  key="comments-button"
                  targetDrawer="workflowComments"
                >
                  {({ onClick }) => (
                    <Button
                      className={cx("commentsButton", {
                        "commentsButton--active": isWorkflowCommentsDrawerOpen,
                      })}
                      onClick={onClick}
                      slotLeft={
                        <Icon
                          variant="chat-text"
                          size={24}
                        />
                      }
                      slotRight={<Pill variant="default">{commentCount}</Pill>}
                    >
                      Comments
                    </Button>
                  )}
                </TimelinePanel.Trigger>,
              ]
            : []),
          <Button
            key="primary"
            variant="primary"
            onClick={handleChangeStatus}
          >
            Change Status
          </Button>,
        ]}
        renderMeta={
          metaSegments.length === 0
            ? undefined
            : ({ MetaSegments }) => <MetaSegments values={metaSegments} />
        }
        {...(workflow
          ? {
              rightSlot: <WorkflowCommentsDrawer workflow={workflow} />,
            }
          : {})}
      >
        <div>
          <div>
            <WorkflowDetailKeyValuePanel
              workflowDetails={workflowWithTrigger}
            />
            {workflowWithTrigger?.asset_trigger ||
            workflowWithTrigger?.workflow_trigger ||
            workflowWithTrigger?.form_trigger ? (
              <WorkflowAnsersKeyValuePanel
                workflowDetails={workflowWithTrigger}
              />
            ) : null}
          </div>
        </div>
        <div className={cx("filterContainer")}>
          <ButtonToggleGroup
            className={cx("contentSwitch")}
            items={[
              { label: "Actions", dataKey: "actions" },
              { label: "Form Submissions", dataKey: "submissions" },
            ]}
            activeKey={context}
            onChange={(newStatus: "actions" | "submissions") =>
              setSearchParams({ context: newStatus })
            }
          />
        </div>
        <div>
          {context === "actions" ? (
            <WorkflowTasksTablePanel
              {...tasksStorageKeys}
              isWithinWorkflowDetail
              isDisplayingActions
              taskFilters={{ workflow_id: workflowId }}
            />
          ) : (
            <WorkflowFormSubmissionsTablePanel
              {...formsStorageKeys}
              workflowId={workflowId}
            />
          )}
        </div>
      </Page>

      {workflow_template ? (
        <WorkflowDetailDeleteDialog
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          isDeleteModalOpen={isDeleteModalOpen}
          workflowId={workflowId}
          workflowCategoryId={workflow_template.workflow_category.id}
          workflowTemplateId={workflow_template.id}
        />
      ) : null}

      {workflow ? (
        <>
          <WorkflowDetailStatusDialog
            setIsStatusModalOpen={setIsStatusModalOpen}
            isStatusModalOpen={isStatusModalOpen}
            workflowDetails={workflow}
          />
        </>
      ) : null}
    </>
  );
};
