import {
  useGetOneDevice,
  useUpdateDevice,
} from "#src/components/hooks/adapters/utils/useDevices";
import useDeviceTypes from "#src/components/hooks/useDeviceTypes";
import useMeasurementTypes from "#src/components/hooks/useMeasurementTypes";
import useLocalization from "#src/hooks/useLocalization";
import { AssetType, DeviceStatus } from "@validereinc/domain";
import cloneDeep from "lodash/cloneDeep";
import startCase from "lodash/startCase";
import React from "react";
import AssetEditDialog from "./AssetEditDialog/AssetEditDialog";
import { AssetEditDialogPropTypes } from "./AssetEditDialog/types";

const EditDeviceDialog = (props: {
  deviceId?: string;
  onClose: () => void;
  onEdit?: () => void;
}) => {
  const { localize } = useLocalization();

  const [deviceTypes, deviceTypesLoadingState] = useDeviceTypes();
  const [measurementTypes, measurementTypesLoadingState] =
    useMeasurementTypes();

  const { data: assetDetails } = useGetOneDevice(
    { id: props.deviceId ?? "" },
    { enabled: !!props.deviceId }
  );
  const updateDeviceMutation = useUpdateDevice({
    onSuccess: () => {
      props.onEdit?.();
      props.onClose();
    },
    successMessage: `Successfully updated device attribute(s)`,
  });

  const isUpdating = updateDeviceMutation.isLoading;
  const isLoading =
    isUpdating ||
    measurementTypesLoadingState !== "loaded" ||
    deviceTypesLoadingState !== "loaded";

  const deviceType = deviceTypes.find(
    (deviceType) => deviceType.id === assetDetails?.data?.type?.id
  );

  const attributeFieldsBasedOnDeviceType =
    deviceType?.attributes.map((attribute) => {
      return {
        display_name: attribute.name,
        field_name: `attributes.${attribute.id}`,
        data_type: attribute.data_type,
        is_required: attribute.is_required,
        is_state_managed: false,
      };
    }) ?? [];

  const primaryFields: AssetEditDialogPropTypes["primaryFields"] = [
    {
      display_name: "Name",
      field_name: "name",
      data_type: "string",
      is_required: true,
      is_state_managed: false,
    },
    {
      display_name: "Status",
      field_name: "status",
      data_type: "pick-list",
      pick_list_values: Object.values(DeviceStatus).map((value) => ({
        id: value,
        name: startCase(value),
      })),
      is_required: true,
      is_state_managed: false,
    },
    {
      display_name: "Valid Measurement Types",
      field_name: "measurement_types",
      data_type: "multi-pick-list",
      pick_list_values: measurementTypes,
      is_required: true,
      is_state_managed: false,
    },
    ...attributeFieldsBasedOnDeviceType,
  ];

  const readOnlyFields = [
    {
      display_name: `${localize("Device")} Type`,
      field_name: "type",
      value: assetDetails?.data?.type?.name ?? "-",
    },
    {
      display_name: localize("facility"),
      field_name: "facility",
      value: assetDetails?.data?.facility?.name ?? "-",
    },
  ];

  return (
    <AssetEditDialog
      primaryFields={primaryFields}
      readOnlyFields={readOnlyFields}
      assetId={props.deviceId}
      assetType={AssetType.DEVICE}
      dialogProps={{ title: `Edit ${localize("Device")} Attributes` }}
      onClose={() => {
        if (isUpdating) return;
        props.onClose();
      }}
      isLoading={isLoading}
      onUpdate={(newValues) => {
        // Make sure all the attribute values are sent to the API together:
        const cleanValues = cloneDeep(newValues);

        if (cleanValues.attributes && assetDetails) {
          cleanValues.attributes = {
            ...assetDetails.data.attributes,
            ...newValues.attributes,
          };
        }

        updateDeviceMutation.mutate({
          id: props.deviceId ?? "",
          data: cleanValues,
        });
      }}
      assetDetails={assetDetails?.data}
    />
  );
};

export default EditDeviceDialog;
