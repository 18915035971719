import { RecordEquipmentSummaryPanel } from "#batteries-included-components/Panels/SummaryPanels/RecordEquipmentSummaryPanel";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { ORGANIZATION_BREADCRUMB } from "#routes/organization";
import { EQUIPMENT_LIST_BREADCRUMB } from "#routes/organization/equipment";
import { RecordValueTablePanel } from "#src/batteries-included-components/Panels/TablePanels/RecordValueTablePanel";
import { useActionDetails } from "#src/components/hooks/useActionDetails";
import {
  EquipmentContext,
  EquipmentProvider,
} from "#src/contexts/EquipmentContext";
import { RecordContext, RecordProvider } from "#src/contexts/RecordContext";
import { Page, Pill, PillProps } from "@validereinc/common-components";
import { getRecordStatusFromValues } from "@validereinc/domain";
import { yearMonthName } from "@validereinc/utilities";
import React, { useContext } from "react";
import {
  EQUIPMENT_RECORD_DETAIL_BREADCRUMB,
  EQUIPMENT_RECORD_DETAIL_PAGE_TITLE,
} from ".";
import { EQUIPMENT_DETAIL_BREADCRUMB } from "../../";

const EquipmentRecordDetailPageContent = () => {
  const { equipment } = useContext(EquipmentContext) ?? {};
  const { record } = useContext(RecordContext) ?? {};

  const lockStatus = record?.values
    ? getRecordStatusFromValues(record.values)
    : "";

  const recordStatusOptions: Record<
    string,
    { label: string; variant: PillProps["variant"] }
  > = {
    locked: { label: "Locked", variant: "default" },
    partially_locked: { label: "Partially Locked", variant: "info" },
    unlocked: { label: "Unlocked", variant: "info" },
  };

  const pageTitle = record
    ? `${yearMonthName(record.year_month)} Record`
    : EQUIPMENT_RECORD_DETAIL_PAGE_TITLE;

  const updateInfo = useActionDetails({
    type: "edit",
    date: record?.updated_at,
    byUserId: record?.updated_by,
  });

  const breadcrumbs = useBreadcrumbs(
    [
      ORGANIZATION_BREADCRUMB,
      EQUIPMENT_LIST_BREADCRUMB,
      EQUIPMENT_DETAIL_BREADCRUMB,
      EQUIPMENT_RECORD_DETAIL_BREADCRUMB,
    ],
    {
      2: equipment?.name,
      3: pageTitle,
    }
  );

  return (
    <Page
      category="Equipment Record"
      breadcrumbs={breadcrumbs}
      title={
        !record ? (
          pageTitle
        ) : (
          <>
            {pageTitle}&nbsp;
            {lockStatus ? (
              <Pill
                variant={recordStatusOptions[lockStatus].variant}
                hasDot={false}
              >
                {recordStatusOptions[lockStatus].label}
              </Pill>
            ) : null}
          </>
        )
      }
      renderMeta={
        !updateInfo.description
          ? undefined
          : ({ MetaSegments }) => (
              <MetaSegments values={[updateInfo.description]} />
            )
      }
    >
      <RecordEquipmentSummaryPanel />
      <RecordValueTablePanel />
    </Page>
  );
};

export const EquipmentRecordDetailPage = () => (
  <EquipmentProvider>
    <RecordProvider>
      <EquipmentRecordDetailPageContent />
    </RecordProvider>
  </EquipmentProvider>
);
