import { useListDatasets } from "#hooks/adapters/useDatasets";
import {
  useListTransactions,
  useOriginalSubmissionFileDownload,
} from "#hooks/adapters/useTransactions";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import {
  FiltersConfig,
  getSourceTypeDisplayLabel,
  getStatusDisplayLabel,
  getTransactionStatusVariant,
} from "#routes/data-tools/submissions/datasets/DataSetSubmissionsList.helpers";
import { DataSetSubmissionDetailRoutePath } from "#routes/data-tools/submissions/datasets/[transactionId]/detail";
import { getTransactionTitle } from "#routes/data-tools/submissions/datasets/[transactionId]/detail/DataSetSubmissionDetail.helpers";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import {
  DatasetType,
  SortDirection,
  TransactionType,
} from "@validereinc/domain";
import startCase from "lodash/startCase";
import React, { useMemo } from "react";
import { DataSetSubmissionsListRoutePath } from ".";

export const DataSetSubmissionsTablePanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
}: StorageKeys) => {
  const [filters] = useFilters(filterConfigStorageKey);

  const downloadOriginalFileMutation = useOriginalSubmissionFileDownload();

  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "created_at",
      sortDirection: SortDirection.DESCENDING,
    },
    filters
  );

  const { data: datasetData, isLoading: isDatasetDataLoading } =
    useListDatasets();

  const { data: transactionData, isLoading: isTransactionDataLoading } =
    useListTransactions({
      page: tableState.page,
      pageSize: tableState.pageSize,
      sortBy: tableState.sortBy,
      sortDirection: tableState.sortDirection,
      filters: {
        only_latest: true,
        ...(filters?.[FiltersConfig.status.name]
          ? { status: filters[FiltersConfig.status.name] }
          : {}),
        ...(filters?.[FiltersConfig.source.name]
          ? { source: filters[FiltersConfig.source.name] }
          : {}),
        ...(filters?.[FiltersConfig.date.name]?.from
          ? {
              submitted_after: new Date(
                filters[FiltersConfig.date.name].from
              ).toISOString(),
            }
          : {}),
        ...(filters?.[FiltersConfig.date.name]?.to
          ? {
              submitted_before: new Date(
                filters[FiltersConfig.date.name].to
              ).toISOString(),
            }
          : {}),
        ...(filters?.[FiltersConfig.dataset.name]
          ? { dataset_id: filters[FiltersConfig.dataset.name] }
          : {}),
        ...(filters?.[FiltersConfig.search_name.name]
          ? { file_key: filters[FiltersConfig.search_name.name] }
          : {}),
        ...(filters?.[FiltersConfig.search_transaction.name]
          ? { id: filters[FiltersConfig.search_transaction.name] }
          : {}),
      },
    });

  const getDataset = (datasetId?: string) =>
    datasetId && datasetData?.data
      ? datasetData?.data.find(({ id }) => id === datasetId)
      : undefined;

  const items = useMemo(
    () =>
      (transactionData?.data ?? []).map(
        (transaction) => ({
          ...transaction,
          dataset: getDataset(transaction.dataset_id),
        }),
        [transactionData, datasetData]
      ),
    [transactionData, datasetData]
  );

  const headers: Array<
    HeaderType<TransactionType & { dataset?: DatasetType }>
  > = [
    {
      key: "id",
      label: "Name",
      renderComponent: ({ item }) => (
        <RoutingLink
          to={DataSetSubmissionDetailRoutePath.toLink({
            pathParams: { transactionId: item?.transaction_id },
          })}
        >
          {getTransactionTitle(item, item.dataset)}
        </RoutingLink>
      ),
    },
    {
      key: "created_at",
      label: "Created At",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
    {
      key: "dataset_id",
      label: "Dataset",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.TextCell
          value={item.dataset ? startCase(item.dataset.name) : null}
        />
      ),
    },
    {
      key: "source",
      label: "Import Type",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.TextCell
          value={getSourceTypeDisplayLabel(item.source)}
        />
      ),
    },
    {
      key: "original_file_name",
      label: "File Name",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.TextCell value={item.original_file_name} />
      ),
    },
    {
      key: "status",
      label: "Status",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={getTransactionStatusVariant(item.status)}
          value={getStatusDisplayLabel(item.status)}
        />
      ),
    },
  ];

  const getItemActions = ({ item }: { item: TransactionType }) => [
    {
      label: "Download Original Submission",
      buttonProps: {
        onClick: () =>
          downloadOriginalFileMutation.mutate({
            transactionId: item.transaction_id,
            fileName: item.original_file_name,
          }),
        icon: "download",
      },
    },
  ];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{ title: DataSetSubmissionsListRoutePath.title }}
      dataTableProps={{
        variant: "simplicity-first",
        headers,
        items,
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        getItemActions,
        isLoading: isDatasetDataLoading || isTransactionDataLoading,
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: transactionData?.total_entries ?? 0,
        },
      }}
    />
  );
};
