import { EstimationMethodCalculationsTab } from "#batteries-included-components/Tabs/EstimationMethod/CalculationsTab";
import { ConfigurationTab } from "#batteries-included-components/Tabs/EstimationMethod/ConfigurationTab/ConfigurationTab";
import { EstimationMethodOverviewTab } from "#batteries-included-components/Tabs/EstimationMethod/OverviewTab";
import { EstimationMethodRecordsTab } from "#batteries-included-components/Tabs/EstimationMethod/RecordsTab";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate, useParams, useSearchParams } from "#routers/hooks";
import { linkToFacilities } from "#src/Routers/links";
import { EstimationMethodEditDialog } from "#src/batteries-included-components/Dialogs/EstimationMethodEditDialog";
import type { EstimationMethodDetailViewFilterType } from "#src/batteries-included-components/FilterAreas/EstimationMethodsFilterAreas/EstimationMethodsFilterAreas";
import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";
import { IsFeatureAvailable } from "#src/batteries-included-components/Layouts/Authorization/IsFeatureAvailable";
import {
  EstimationMethodDetailContextProvider,
  useEstimationMethodDetailContext,
} from "#src/batteries-included-components/Layouts/EstimationMethod/Detail";
import { useGetOneEquipment } from "#src/components/hooks/adapters/useEquipment";
import { useActionDetails } from "#src/components/hooks/useActionDetails";
import {
  useDeleteEstimationMethod,
  useGetEstimationMethod,
} from "#src/components/hooks/useEstimationMethod";
import { MUTATION_STATUS } from "#src/constants";
import useLocalization from "#src/hooks/useLocalization";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { ORGANIZATION_BREADCRUMB } from "#src/routes/organization";
import { EQUIPMENT_LIST_BREADCRUMB } from "#src/routes/organization/equipment";
import {
  EQUIPMENT_DETAIL_BREADCRUMB,
  linkToEquipmentDetail,
} from "#src/routes/organization/equipment/[equipmentId]";
import { ESTIMATION_METHOD_LIST_BREADCRUMB } from "#src/routes/organization/equipment/[equipmentId]/estimation-method";
import { linkToEstimationMethodCalculationResult } from "#src/routes/organization/equipment/[equipmentId]/estimation-method/[methodId]/result/[resultId]/detail";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Dialog,
  Link,
  Page,
  Pill,
  Tab,
} from "@validereinc/common-components";
import {
  AssetType,
  EquipmentType,
  ReportingGroupDomain,
} from "@validereinc/domain";
import { yearMonthFormatter } from "@validereinc/utilities";
import parseISO from "date-fns/parseISO";
import React, { useState } from "react";
import { ESTIMATION_METHOD_DETAIL_BREADCRUMB } from "./";

export const EstimationMethodDetailPage = () => {
  // state
  const { method, calculator, calculatorVersion, entityId, entityType } =
    useEstimationMethodDetailContext<EquipmentType>() || {};
  const methodDetail = method?.data;
  const calculatorDetail = calculator?.data;
  const { viewConfigStorageKey } = useStorageKey("estimation-method-overview");
  const [viewFilters] =
    useSessionStickyState<EstimationMethodDetailViewFilterType>(
      {
        month: {},
      },
      viewConfigStorageKey
    );

  const period = viewFilters.month?.from
    ? yearMonthFormatter(parseISO(viewFilters.month.from))
    : undefined;

  const { data: equipmentDetail, isLoading: isEquipmentLoading } =
    useGetOneEquipment(entityId, { period }, { enabled: !!entityId });
  const [methodData] = useGetEstimationMethod(
    method?.data?.id,
    entityType,
    viewFilters.month?.from
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const { localize, isLoading: isMappingLoading } = useLocalization();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteEstimationMethod, deleteEstimationMethodStatus] =
    useDeleteEstimationMethod();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const reportingGroupsQuery = useQuery({
    queryKey: ["reportingGroups"],
    queryFn: ReportingGroupDomain.getList,
  });
  const reportingGroups = reportingGroupsQuery?.data?.data ?? [];

  // callbacks
  const handleActiveTabKeyChange = (tabKey: string) => {
    setSearchParams({ ...searchParams, tab: tabKey });
  };

  const handleDelete = () => {
    if (!methodDetail?.id) {
      return;
    }

    deleteEstimationMethod(methodDetail.id, AssetType.EQUIPMENT);
    navigate({
      pathname: linkToEquipmentDetail(equipmentDetail?.id ?? entityId),
      query: {
        tab: "estimationMethods",
      },
    });
  };

  // effects & hooks
  const navigate = useNavigate();

  const createInfo = useActionDetails({
    type: "create",
    date: methodDetail?.created_at,
  });

  const editInfo = useActionDetails({
    type: "edit",
    date: methodDetail?.updated_at,
    byUserId: methodDetail?.updated_by,
  });

  // computed
  const breadcrumbs = useBreadcrumbs(
    [
      ORGANIZATION_BREADCRUMB,
      EQUIPMENT_LIST_BREADCRUMB,
      EQUIPMENT_DETAIL_BREADCRUMB,
      ESTIMATION_METHOD_LIST_BREADCRUMB,
      ESTIMATION_METHOD_DETAIL_BREADCRUMB,
    ],
    {
      2: equipmentDetail?.name,
      4: methodDetail?.name,
    }
  );

  const hasReportingGroups =
    methodData?.reporting_groups && methodData.reporting_groups.length > 0;

  const detailsPanelData = [
    {
      title: "Created Date",
      value: createInfo.formattedDate,
    },
    {
      title: "Created By",
      value: methodDetail?.createdBy?.name,
    },
    {
      title: "Reporting Scenarios",
      value: !hasReportingGroups ? null : (
        <>
          {methodData?.reporting_groups?.map((group) => (
            <Pill
              isCapitalized={false}
              key={group.name}
              variant="primary"
              isBordered
              hasDot={false}
            >
              {group.name.trim()}
            </Pill>
          ))}
        </>
      ),
      description: hasReportingGroups
        ? undefined
        : "Edit this estimation method to associate reporting scenarios",
    },
    {
      title: localize("Equipment"),
      value: (
        <Link
          onClick={() => {
            navigate({
              pathname: linkToEquipmentDetail(equipmentDetail?.id),
            });
          }}
          label={equipmentDetail?.name ?? "-"}
        />
      ),
    },
    {
      title: localize("Facility"),
      value: (
        <Link
          onClick={() => {
            navigate({
              pathname: linkToFacilities(equipmentDetail?.facility_id),
            });
          }}
          label={equipmentDetail?.facility?.name ?? "-"}
        />
      ),
    },
    {
      title: `${localize("Equipment")} Type`,
      value: equipmentDetail?.type.name,
    },
    {
      title: "Library",
      value: methodDetail?.analytics_library_id || calculatorDetail?.library,
    },
    {
      title: "Calculator Name",
      value: calculatorVersion?.title,
    },
  ];

  const pageActionRow = (
    <>
      <Button
        variant="error-outline"
        onClick={() => setIsDeleteModalOpen(true)}
        isLoading={deleteEstimationMethodStatus === MUTATION_STATUS.LOADING}
        disabled={!methodDetail?.id || !methodDetail?.name}
      >
        Delete
      </Button>
      <Button
        variant="outline"
        onClick={() => setIsEditDialogOpen(true)}
        isLoading={deleteEstimationMethodStatus === MUTATION_STATUS.LOADING}
        disabled={!methodDetail?.id || !methodDetail?.name}
      >
        Edit
      </Button>
    </>
  );

  return (
    <>
      <Page
        category="Estimation Method"
        title={
          methodDetail ? (
            <>
              {methodDetail?.name}&nbsp;
              <Pill
                variant={
                  methodDetail.status === "active" ? "success" : "default"
                }
                hasDot={false}
              >
                {methodDetail?.status}
              </Pill>
            </>
          ) : (
            ""
          )
        }
        // WIP: fix this loading state
        isLoading={isEquipmentLoading || !methodDetail}
        breadcrumbs={breadcrumbs}
        onActiveTabKeyChange={handleActiveTabKeyChange}
        activeTabKey={searchParams?.tab ?? "overview"}
        actionRow={pageActionRow}
        renderMeta={
          !editInfo.description
            ? undefined
            : ({ MetaSegments }) => (
                <MetaSegments values={[editInfo.description]} />
              )
        }
      >
        <Tab
          tabKey="overview"
          title="Overview"
        >
          <EstimationMethodOverviewTab
            detailsPanelProps={{
              data: detailsPanelData,
              isLoading: isMappingLoading,
            }}
          />
        </Tab>
        <Tab
          tabKey="configuration"
          title="Configuration"
        >
          <ConfigurationTab viewConfigStorageKey={viewConfigStorageKey} />
        </Tab>
        <Tab
          tabKey={"calculations"}
          title="Calculations"
        >
          <EstimationMethodCalculationsTab
            onClickSavedResultRecord={(methodId, entityId, yearMonth) =>
              navigate({
                pathname: linkToEstimationMethodCalculationResult(
                  methodId,
                  entityId,
                  yearMonth
                ),
              })
            }
          />
        </Tab>
        <Tab
          tabKey="results"
          title="Results"
        >
          <EstimationMethodRecordsTab
            onClickRow={(methodId, entityId, yearMonth) =>
              navigate({
                pathname: linkToEstimationMethodCalculationResult(
                  methodId,
                  entityId,
                  yearMonth
                ),
              })
            }
          />
        </Tab>
      </Page>
      <Dialog
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title={`Delete "${methodDetail?.name ?? "this estimation method"}"`}
        actionRow={[
          <Button
            key="delete-dialog-action"
            onClick={handleDelete}
            variant="error"
          >
            Delete
          </Button>,
        ]}
      >
        <div>
          Are you sure you want to delete this estimation method? This action
          cannot be undone.
        </div>
      </Dialog>

      <EstimationMethodEditDialog
        reportingGroups={reportingGroups}
        method={methodDetail}
        entityType={AssetType.EQUIPMENT}
        onClose={() => setIsEditDialogOpen(false)}
        onSubmit={() => method?.refetch?.()}
        isEditDialogOpen={isEditDialogOpen}
      />
    </>
  );
};

export const EstimationMethodDetail = () => {
  const { equipmentId, methodId } = useParams<{
    equipmentId: string;
    methodId: string;
  }>();

  return (
    <IsFeatureAvailable
      fallbackChildren={<AccessDeniedLayout />}
      featureFlagQuery="core:estimation_methods"
      permissionQuery="calculator_results:read"
    >
      <EstimationMethodDetailContextProvider
        estimationMethodId={methodId}
        entityType={AssetType.EQUIPMENT}
        entityId={equipmentId}
      >
        <EstimationMethodDetailPage />
      </EstimationMethodDetailContextProvider>
    </IsFeatureAvailable>
  );
};
