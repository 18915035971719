import { useFlowProductTypes, useFlowTypes } from "#hooks/useFlowConstants";
import { FlowDetailRoute } from "#routes/organization/flows/[flowId]/detail";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useGetOneFlow } from "#src/components/hooks/adapters/useFlows";
import { useGetOneReportingGroup } from "#src/components/hooks/adapters/useReportingGroups";
import { RecordContext } from "#src/contexts/RecordContext";
import useLocalization from "#src/hooks/useLocalization";
import { KeyValuePanel, Pill } from "@validereinc/common-components";
import React, { useContext } from "react";

export const RecordFlowSummaryPanel = ({
  flowId,
  period,
}: {
  flowId: string;
  period: string;
}) => {
  const { localize } = useLocalization();
  const { data: flow, isLoading: isFlowLoading } = useGetOneFlow({
    flowId,
    period,
  });
  const { record } = useContext(RecordContext) ?? {};
  const reportingGroupQuery = useGetOneReportingGroup(
    record?.reporting_group_id
  );

  const { flowTypes } = useFlowTypes();
  const { flowProductTypes } = useFlowProductTypes();

  return (
    <KeyValuePanel
      panelMaxColumnCount={2}
      panelKeyValueListProps={{ variant: "shaded" }}
      panelProps={{ title: "Details", isFluidY: false, loaded: !isFlowLoading }}
      data={[
        {
          title: "Reporting Scenario",
          value: reportingGroupQuery.data?.data?.name ? (
            <Pill
              isCapitalized={false}
              variant="primary"
              hasDot={false}
              isBordered
            >
              {reportingGroupQuery.data?.data?.name}
            </Pill>
          ) : null,
        },
        {
          title: `${localize("Flow")}`,
          value: (
            <RoutingLink
              to={FlowDetailRoute.toLink({
                pathParams: { flowId },
              })}
            >
              {flow?.name ?? "-"}
            </RoutingLink>
          ),
        },
        {
          title: "Type",
          value: flowTypes?.find(({ id }) => id === flow?.type)?.name,
        },
        {
          title: "Product Type",
          value: flowProductTypes?.find(({ id }) => id === flow?.product_type)
            ?.name,
        },
      ]}
    />
  );
};
