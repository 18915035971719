import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import {
  DateSelectorInput,
  Radio,
  TextInput,
  useFormContext,
} from "@validereinc/common-components";
import {
  AttributeDataType,
  FormSchemaQuestionBaseSchema,
  FormSchemaQuestionType,
} from "@validereinc/domain";
import { DateTimeSubstitutions } from "@validereinc/domain-controllers/logic/forms";
import classNames from "classnames/bind";
import { isAfter, isBefore } from "date-fns";
import React, { useState } from "react";
import styles from "./QuestionValidationRulesFields.module.css";

const cx = classNames.bind(styles);

const StartBoundTypes = {
  NONE: "none",
  AFTER_OR_EQUAL_TO_NOW: "after_or_equal_to_now",
  AFTER_OR_EQUAL_TO_DATE: "after_or_equal_to_date",
} as const;

type StartBoundTypesType =
  | (typeof StartBoundTypes)[keyof typeof StartBoundTypes]
  | undefined;

const EndBoundTypes = {
  NONE: "none",
  BEFORE_OR_EQUAL_TO_NOW: "before_or_equal_to_now",
  BEFORE_OR_EQUAL_TO_DATE: "beforer_or_equal_to_date",
} as const;

type EndBoundTypesType =
  | (typeof EndBoundTypes)[keyof typeof EndBoundTypes]
  | undefined;

const formSchemaQuestionBaseSchemaKeys =
  FormSchemaQuestionBaseSchema.keyof().Enum;

export const QuestionValidationRulesFields = () => {
  const form = useFormContext();
  const { measurementUnits } = useMeasurementTypes();

  const [startBoundType, setStartBoundType] = useState<StartBoundTypesType>(
    !form.watch("after")
      ? StartBoundTypes.NONE
      : form.watch("after") === DateTimeSubstitutions.$NOW
        ? StartBoundTypes.AFTER_OR_EQUAL_TO_NOW
        : StartBoundTypes.AFTER_OR_EQUAL_TO_DATE
  );
  const [endBoundType, setEndBoundType] = useState<EndBoundTypesType>(
    !form.watch("before")
      ? EndBoundTypes.NONE
      : form.watch("before") === DateTimeSubstitutions.$NOW
        ? EndBoundTypes.BEFORE_OR_EQUAL_TO_NOW
        : EndBoundTypes.BEFORE_OR_EQUAL_TO_DATE
  );

  const dataType = form.watch("data_type");
  const questionType = form.watch("type");
  const measurementUnit = form.watch("measurement_unit");
  const minimum = form.watch("minimum");
  const maximum = form.watch("maximum");
  const after = form.watch("after");
  const before = form.watch("before");

  const handleOnStartBoundTypeChange = (
    newStartBoundType: StartBoundTypesType
  ) => {
    setStartBoundType(newStartBoundType);

    switch (newStartBoundType) {
      case StartBoundTypes.NONE:
      case StartBoundTypes.AFTER_OR_EQUAL_TO_DATE:
        form.setValue("after", undefined);
        break;
      case StartBoundTypes.AFTER_OR_EQUAL_TO_NOW:
        form.setValue("after", DateTimeSubstitutions.$NOW);
        break;
    }
  };

  const handleOnEndBoundTypeChange = (newEndBoundType: EndBoundTypesType) => {
    setEndBoundType(newEndBoundType);

    switch (newEndBoundType) {
      case EndBoundTypes.NONE:
      case EndBoundTypes.BEFORE_OR_EQUAL_TO_DATE:
        form.setValue("before", undefined);
        break;
      case EndBoundTypes.BEFORE_OR_EQUAL_TO_NOW:
        form.setValue("before", DateTimeSubstitutions.$NOW);
        break;
    }
  };

  const displayMeasurementUnit = measurementUnits.find(
    ({ id }) => measurementUnit === id
  );

  return (
    <div>
      {dataType === AttributeDataType.STRING ? (
        <TextInput
          label="Max Character Count"
          name={formSchemaQuestionBaseSchemaKeys.max_length}
          placeholder="Enter Max Character Count..."
          type="number"
          formatType="integer"
          validate={{
            min: (characterCount: number) =>
              characterCount > 0 ||
              "Max Character Count must be greater than 0",
          }}
          isRequired
          isFluid
        />
      ) : null}

      {[AttributeDataType.NUMBER, AttributeDataType.INTEGER].includes(
        dataType
      ) ? (
        <>
          <TextInput
            label="Lower Bound"
            name={formSchemaQuestionBaseSchemaKeys.minimum}
            placeholder="Enter Lower Bound..."
            type="number"
            formatType={
              dataType === AttributeDataType.INTEGER ? "integer" : "number"
            }
            validate={{
              max: (value: number) =>
                value && maximum
                  ? value < maximum ||
                    `Lower Bound must be less than Upper Bound`
                  : true,
            }}
            onBlur={() =>
              maximum
                ? form.trigger(formSchemaQuestionBaseSchemaKeys.maximum)
                : null
            }
            isFluid
          />
          <TextInput
            label="Upper Bound"
            name={formSchemaQuestionBaseSchemaKeys.maximum}
            placeholder="Enter Upper Bound..."
            type="number"
            formatType={
              dataType === AttributeDataType.INTEGER ? "integer" : "number"
            }
            validate={{
              min: (value: number) =>
                value && minimum
                  ? value > minimum ||
                    `Upper Bound must be greater than Lower Bound`
                  : true,
            }}
            onBlur={() =>
              minimum
                ? form.trigger(formSchemaQuestionBaseSchemaKeys.minimum)
                : null
            }
            isFluid
          />
        </>
      ) : null}

      {[
        FormSchemaQuestionType.MEASUREMENT,
        FormSchemaQuestionType.CALCULATED_MEASUREMENT,
      ].includes(questionType) ? (
        <>
          <TextInput
            name={formSchemaQuestionBaseSchemaKeys.minimum}
            label="Lower Bound"
            placeholder="Enter Lower Bound..."
            type="number"
            formatType="number"
            validate={{
              max: (value: number) =>
                value && maximum
                  ? value < maximum ||
                    `Lower Bound must be less than Upper Bound`
                  : true,
            }}
            onBlur={() =>
              maximum
                ? form.trigger(formSchemaQuestionBaseSchemaKeys.maximum)
                : null
            }
            unit={displayMeasurementUnit?.name?.symbol ?? measurementUnit}
            isFluid
          />
          <TextInput
            name={formSchemaQuestionBaseSchemaKeys.maximum}
            label="Upper Bound"
            placeholder="Enter Upper Bound..."
            type="number"
            formatType="number"
            validate={{
              min: (value: number) =>
                value && minimum
                  ? value > minimum ||
                    `Upper Bound must be greater than Lower Bound`
                  : true,
            }}
            onBlur={() =>
              minimum
                ? form.trigger(formSchemaQuestionBaseSchemaKeys.minimum)
                : null
            }
            unit={displayMeasurementUnit?.name?.symbol ?? measurementUnit}
            isFluid
          />
        </>
      ) : null}

      {[AttributeDataType.DATE, AttributeDataType.DATE_TIME].includes(
        dataType
      ) ? (
        <>
          <p className={cx("boldText")}>Start Bound</p>
          <div className={cx("radioContainer")}>
            <Radio
              name="startBoundType"
              value={startBoundType}
              onChange={(newValue: StartBoundTypesType) => {
                handleOnStartBoundTypeChange(newValue);
              }}
              options={[
                {
                  label: "Do not define start bound",
                  value: StartBoundTypes.NONE,
                },
                {
                  label: `After or equal to ${dataType === AttributeDataType.DATE_TIME ? "date & time" : "date"} of submission`,
                  value: StartBoundTypes.AFTER_OR_EQUAL_TO_NOW,
                },
                {
                  label: `Define ${dataType === AttributeDataType.DATE_TIME ? "date & time" : "date"}`,
                  value: StartBoundTypes.AFTER_OR_EQUAL_TO_DATE,
                },
              ]}
              labelKey="label"
              valueKey="value"
              isDisabled={false}
              isLoading={false}
            />
          </div>
          {startBoundType === StartBoundTypes.AFTER_OR_EQUAL_TO_DATE ? (
            <DateSelectorInput
              name={formSchemaQuestionBaseSchemaKeys.after}
              placeholder="Enter Start Bound..."
              variant={dataType === AttributeDataType.DATE ? "day" : "time"}
              validate={{
                before: (value: string) =>
                  value && before && before !== DateTimeSubstitutions.$NOW
                    ? isBefore(new Date(value), new Date(before)) ||
                      "Start Bound must be before End Bound"
                    : true,
              }}
              onBlur={() => {
                before
                  ? form.trigger(formSchemaQuestionBaseSchemaKeys.before)
                  : null;
              }}
              isRange={false}
              isFluid
            />
          ) : null}
          <p className={cx("boldText")}>End Bound</p>
          <div className={cx("radioContainer")}>
            <Radio
              name="endBoundType"
              value={endBoundType}
              onChange={(newValue: EndBoundTypesType) => {
                handleOnEndBoundTypeChange(newValue);
              }}
              options={[
                {
                  label: "Do not define start bound",
                  value: EndBoundTypes.NONE,
                },
                {
                  label: `Before or equal to ${dataType === AttributeDataType.DATE_TIME ? "date & time" : "date"} of submission`,
                  value: EndBoundTypes.BEFORE_OR_EQUAL_TO_NOW,
                },
                {
                  label: `Define ${dataType === AttributeDataType.DATE_TIME ? "date & time" : "date"}`,
                  value: EndBoundTypes.BEFORE_OR_EQUAL_TO_DATE,
                },
              ]}
              labelKey="label"
              valueKey="value"
              isDisabled={false}
              isLoading={false}
            />
          </div>
          {endBoundType === EndBoundTypes.BEFORE_OR_EQUAL_TO_DATE ? (
            <DateSelectorInput
              name={formSchemaQuestionBaseSchemaKeys.before}
              placeholder="Enter End Bound..."
              variant={dataType === AttributeDataType.DATE ? "day" : "time"}
              validate={{
                after: (value: string) =>
                  value && after && after !== DateTimeSubstitutions.$NOW
                    ? isAfter(new Date(value), new Date(after)) ||
                      "End Bound must be after Start Bound"
                    : true,
              }}
              onBlur={() => {
                after
                  ? form.trigger(formSchemaQuestionBaseSchemaKeys.after)
                  : null;
              }}
              isRange={false}
              isFluid
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
};
