import {
  useHasPermission,
  useIsFeatureAvailable,
} from "#src/contexts/AuthenticatedContext.helpers";
import { displayPluralizedAssetType } from "#src/utils/display";
import { ExceptionUtils } from "#src/utils/exception";
import { useQueryClient } from "@tanstack/react-query";
import { useAlert } from "@validereinc/common-components";
import { AssetTypeType, Resources } from "@validereinc/domain";
import { useRef, useState } from "react";
import { create } from "zustand";
import { useDeleteOneComment } from "./adapters/useComments";

type CommentsStoreState = {
  /** Stores comment counts for each comments drawer. */
  commentCounts: Record<string, number>;
  /** Sets comment count for a specific comments drawer. */
  setCommentCounts: (drawer: string, count: number) => void;
};

export const useCommentsStore = create<CommentsStoreState>((set) => ({
  commentCounts: {},
  setCommentCounts: (drawer: string, count: number) =>
    set((state) => ({
      commentCounts: {
        ...state.commentCounts,
        [drawer]: count,
      },
    })),
}));

export const useCheckCommentPermissions = (
  workflowAssetType?: AssetTypeType
) => {
  const [canReadComments] = useHasPermission("comments:read");
  const [canWriteComments] = useHasPermission("comments:write");
  const [canDeleteCommentsAsAdmin] = useHasPermission("comments:delete_admin");

  const pluralizedAssetType = displayPluralizedAssetType(workflowAssetType);

  const [canReadAssetType] = useIsFeatureAvailable({
    featureFlagQuery: `core:${pluralizedAssetType.toLowerCase()}`,
    permissionQuery: `${pluralizedAssetType.toLowerCase()}:read`,
  });
  const [canWriteAssetType] = useIsFeatureAvailable({
    featureFlagQuery: `core:${pluralizedAssetType.toLowerCase()}`,
    permissionQuery: `${pluralizedAssetType.toLowerCase()}:write`,
  });

  return {
    canReadComments,
    canWriteComments,
    canDeleteCommentsAsAdmin,
    canReadAssetType,
    canWriteAssetType,
  };
};

export const useScrollToComment = () => {
  const [newlyCreatedCommentId, setNewlyCreatedCommentId] = useState<
    string | null
  >(null);
  const commentsEndRef = useRef<HTMLDivElement | null>(null);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);

  const handleCreateComment = (commentId: string) => {
    setNewlyCreatedCommentId(commentId);
    setShouldScrollToBottom(true);
  };

  return {
    newlyCreatedCommentId,
    setNewlyCreatedCommentId,
    commentsEndRef,
    shouldScrollToBottom,
    setShouldScrollToBottom,
    handleCreateComment,
  };
};

export const useDeleteComment = () => {
  const queryClient = useQueryClient();
  const { addAlert } = useAlert();

  const [showDeleteCommentDialog, setShowDeleteCommentDialog] = useState(false);
  const [commentIdToDelete, setCommentIdToDelete] = useState<string | null>(
    null
  );

  const handleDeleteComment = (commentId: string) => {
    deleteCommentMutation.mutate({
      id: commentId,
    });
  };

  const deleteCommentMutation = useDeleteOneComment({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [Resources.COMMENT],
      });
      addAlert?.({
        variant: "success",
        message: "Successfully deleted comment.",
      });
    },
    onError: (error: unknown) => {
      ExceptionUtils.reportException(error, "error", {
        hint: "Error deleting comment",
      });
      addAlert?.({
        variant: "error",
        message: "Failed to delete comment.",
      });
    },
    noAlerts: true,
  });

  return {
    handleDeleteComment,
    showDeleteCommentDialog,
    setShowDeleteCommentDialog,
    commentIdToDelete,
    setCommentIdToDelete,
    deleteCommentMutation,
  };
};
