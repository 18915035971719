import { WorkflowDetailsRoutePath } from "#src/routes/workflows/all/[workflowId]";
import { RoutePath } from "#src/utils/route";

export const WorkflowTaskDetailsRoutePath = WorkflowDetailsRoutePath.concat(
  new RoutePath<"taskId">({
    path: "/task/:taskId",
    title: "Workflow Task",
    isHidden: true,
  })
);
