import {
  FormSubmissionWithAnswersType,
  ReportWithDownloadType,
  type FormSubmissionFilterType,
  type FormSubmissionType,
} from "../schemas";
import {
  CreateOneRequestType,
  CreateOneResponseType,
  GetListRequestType,
  GetListResponseType,
  ResourceServiceType,
  UpdateOneRequestType,
  UpdateOneResponseType,
  fetchAndCollate,
  getFilterObject,
} from "../util";
import { SavedFiltersDomain, type SavedFilterServiceType } from "./";
import { restAPI } from "./api";

type FormSubmissionServiceType = Pick<
  ResourceServiceType<FormSubmissionType>,
  "getList" | "getOne" | "createOne" | "deleteOne" | "exportList"
> & {
  // The only allowed fields for updating are status and answers:
  updateOne: (
    params: UpdateOneRequestType<Pick<FormSubmissionType, "status" | "answers">>
  ) => Promise<UpdateOneResponseType<FormSubmissionType>>;
  answers: {
    getList: (
      params: GetListRequestType<
        Pick<
          FormSubmissionType,
          "id" | "created_at" | "updated_at" | "created_by" | "status"
        >,
        {
          form_schema_id: string;
          version: number;
        }
      >
    ) => Promise<GetListResponseType<FormSubmissionWithAnswersType>>;
    getListV2: (
      params: GetListRequestType<
        Pick<FormSubmissionType, "created_at" | "created_by" | "status"> &
          Record<string, string | number | boolean | string[] | null>,
        {
          form_schema_id: string;
          form_schema_version?: number;
        }
      >
    ) => Promise<GetListResponseType<FormSubmissionWithAnswersType>>;
  };
  revision: {
    createOne: (
      params: CreateOneRequestType<
        Pick<FormSubmissionType, "status" | "answers" | "retrigger_workflow">,
        { id: string }
      >
    ) => Promise<CreateOneResponseType<FormSubmissionType>>;
  };
  savedFilters: SavedFilterServiceType<FormSubmissionFilterType>;
};

export const FormSubmissionAdapter: FormSubmissionServiceType = {
  /**
   * Get a single form submission by id
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/form_submissions/get_form_submission
   * @returns the standard wrapped API response of this endpoint
   */
  getOne: async ({ id }) => {
    const resp = await restAPI.nodeAPI.GET<FormSubmissionType>({
      endpoint: `/form_submissions/${id}`,
    });
    return { data: resp };
  },

  /**
   * Get a list of form submissions by search filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/equipment/search_equipment
   * @returns the standard wrapped API response of this endpoint
   */
  getList: async ({
    filters,
    pageSize,
    page,
    sortBy = "created_at",
    sortDirection = "desc",
    meta: { answers } = {},
  }: GetListRequestType<FormSubmissionFilterType, { answers?: boolean }>) =>
    fetchAndCollate(
      ({ page, pageSize }) =>
        restAPI.nodeAPI.POST<GetListResponseType<FormSubmissionType>>({
          endpoint: "/form_submissions/search",
          query: {
            ...(answers ? { answers: "true" } : {}),
          },
          body: {
            page,
            page_size: pageSize,
            sort_by: sortBy,
            sort_direction: sortDirection,
            filter: getFilterObject(filters),
          },
        }),
      page,
      pageSize
    ),

  answers: {
    /**
     * @deprecated
     * Get form submission answers (form schema version required)
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/form_submissions/search_form_submissions_answers
     * @returns a list of form submission answers
     */
    getList: ({ page, pageSize, sortBy, sortDirection, filters, meta }) => {
      return restAPI.nodeAPI.POST({
        endpoint: `/form_submissions/search/form_schema/${meta?.form_schema_id}/${meta?.version}`,
        body: {
          page,
          page_size: pageSize,
          sort_by: sortBy,
          sort_direction: sortDirection,
          filter: getFilterObject(filters),
        },
      });
    },
    /**
     * Get form submission answers (form schema version optional)
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/form_submissions/search_form_submissions_answers
     * @returns a list of form submission answers
     */
    getListV2: ({ page, pageSize, sortBy, sortDirection, filters, meta }) => {
      return restAPI.nodeAPI.POST({
        endpoint: `/form_submissions/search/form_schema/${meta?.form_schema_id}`,
        query: {
          ...(meta?.form_schema_version
            ? { version: meta.form_schema_version }
            : {}),
        },
        body: {
          page,
          page_size: pageSize,
          sort_by: sortBy,
          sort_direction: sortDirection,
          filter: getFilterObject(filters),
        },
      });
    },
  },

  /**
   * Export a list of form submissions by search filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/form_submissions/export_form_submissions
   * @returns the link to download
   */
  exportList: async ({
    filters,
    sortBy = "created_at",
    sortDirection = "desc",
  }: GetListRequestType<FormSubmissionFilterType>) =>
    restAPI.nodeAPI.POST<ReportWithDownloadType>({
      endpoint: "/form_submissions/export",
      body: {
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: getFilterObject(filters),
      },
    }),
  /**
   * Create a Form Submission
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/form_submissions/create_form_submission
   */
  createOne: async ({
    data,
  }: CreateOneRequestType<
    Pick<FormSubmissionType, "answers" | "form_schema_id"> &
      Partial<
        Pick<FormSubmissionType, "status" | "form_schema_version"> & {
          event_id: string;
          workflow_task_id: string;
        }
      >
  >) => {
    const resp = await restAPI.nodeAPI.POST<FormSubmissionType>({
      endpoint: "/form_submissions",
      body: data,
    });

    return { data: resp };
  },

  updateOne: async ({ id, data }) => {
    const resp = await restAPI.nodeAPI.PUT<FormSubmissionType>({
      endpoint: `/form_submissions/${id}`,
      body: data,
    });
    return { data: resp };
  },

  revision: {
    createOne: async ({ data, meta }) => {
      const resp = await restAPI.nodeAPI.POST<FormSubmissionType>({
        endpoint: `/form_submissions/${meta?.id ?? ""}`,
        body: data,
      });

      return { data: resp };
    },
  },

  deleteOne: async ({ id }) =>
    await restAPI.nodeAPI.DELETE({
      endpoint: `/form_submissions/${id}`,
    }),

  /**
   * Form Submission Saved Filter APIs
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/form_submissions/get_form_submissions_filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/form_submissions/post_form_submissions_filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/form_submissions/put_form_submissions_filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/form_submissions/delete_form_submissions_filter
   */
  savedFilters: SavedFiltersDomain<FormSubmissionFilterType>({
    endpoint: "/form_submissions/filters",
  }),
};
