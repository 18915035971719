import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { linkToFormSubmissionDetail } from "#routers/links";
import {
  FormSubmissionsTableFilterArea,
  FormSubmissionsTableFilterAreaDrawerContent,
} from "#src/batteries-included-components/FilterAreas/FormsFilterAreas";
import { useAddFormSubmissionContext } from "#src/batteries-included-components/Layouts/Form/Submission/Add/AddFormSubmission/AddFormSubmissionContext";
import { FormSubmissionsFilterType } from "#src/batteries-included-components/Panels/FilterPanels/FormSubmissionsFilterPanel/FormSubmissionsFilterPanel";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { getStatusType } from "#src/components/Common/Table/rendererHelper";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { linkToFormTemplateDetail } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
} from "@validereinc/common-components";
import {
  FormSchemaAdapter,
  FormSubmissionAdapter,
  FormSubmissionStatus,
  FormSubmissionType,
  Resources,
  SortDirection,
} from "@validereinc/domain";
import { toFlattenedObject, toStartCaseString } from "@validereinc/utilities";
import React, { useEffect, useMemo, useState } from "react";
import {
  getFormattedFiltersBasedOnFormSchema,
  getHeadersFromSchema,
} from "../FormSubmissionsTablePanel.helpers";

export const AddFormSubmissionsTablePanel = ({
  shouldHideDrafts = true,
}: {
  // submissions of status "draft" are hidden by default
  shouldHideDrafts?: boolean;
}) => {
  const {
    selectedFormSubmissions,
    selectFormSubmission,
    deselectFormSubmission,
    filterConfigStorageKey,
    tableConfigStorageKey,
    viewConfigStorageKey,
    filtersDefaultValues,
  } = useAddFormSubmissionContext();
  const [viewFilters] = useSessionStickyState<FormSubmissionsFilterType>(
    {},
    viewConfigStorageKey
  );
  const [tableFilters] = useSessionStickyState<FormSubmissionsFilterType>(
    {},
    filterConfigStorageKey
  );
  const filters = useMemo(
    () => ({
      ...tableFilters,
      ...viewFilters,
    }),
    [tableFilters, viewFilters]
  );
  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "created_at",
      sortDirection: SortDirection.DESCENDING,
    },
    filters
  );
  const [filteredAnswers, setFilteredAnswers] = useState<Set<string> | null>(
    null
  );
  const { created_at, form_schema_id, created_by, ...restFilters } = filters;

  const schemaQuery = useQuery({
    queryKey: [Resources.FORM_SCHEMA, form_schema_id],
    queryFn: () => {
      if (!form_schema_id) return;

      return FormSchemaAdapter.getOne({
        id: form_schema_id,
      });
    },
    enabled: Boolean(form_schema_id),
    select: (resp) => resp?.data,
    staleTime: 2 * 60 * 1000,
  });

  const formattedAnswerFilters = useMemo(
    () =>
      schemaQuery.data
        ? getFormattedFiltersBasedOnFormSchema(restFilters, schemaQuery.data)
        : null,
    [restFilters, schemaQuery]
  );

  const answersQueryFilter = {
    created_at,
    ...(created_by ? { created_by: created_by } : {}),
    ...(shouldHideDrafts && !restFilters.status?.length
      ? {
          status: Object.values(FormSubmissionStatus).filter(
            (s) => s !== FormSubmissionStatus.DRAFT
          ),
        }
      : { status: restFilters.status }),
    ...formattedAnswerFilters,
  };

  const {
    data: answersQuery,
    hasNextPage: answersQueryHasNextPage,
    isFetching: answersQueryIsFetching,
    isLoading: answersQueryIsLoading,
    fetchNextPage: answerQueryFetchNextPage,
    refetch: refetchAnswers,
  } = useInfiniteQuery({
    queryKey: ["forms", "submissions", "answers", answersQueryFilter],
    queryFn: ({ pageParam }) => {
      if (!form_schema_id) return;

      return FormSubmissionAdapter.answers.getListV2({
        page: pageParam ?? 1,
        pageSize: 500,
        sortBy: tableState.sortBy,
        sortDirection: tableState.sortDirection,
        filters: answersQueryFilter,
        meta: {
          form_schema_id: form_schema_id,
        },
      });
    },
    getNextPageParam: (resp) => {
      return resp
        ? resp.page_number < resp.total_pages
          ? resp.page_number + 1
          : undefined
        : undefined;
    },
    enabled: Boolean(form_schema_id),
    staleTime: 2 * 60 * 1000,
  });

  // name is constructed by the FE and can't be used by the BE to search
  delete restFilters.name;
  const params: Parameters<typeof FormSubmissionAdapter.getList>[0] = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      created_at,
      ...toFlattenedObject({
        "form_schema.id": form_schema_id,
        "form_schema.status": "active",
      }),
      ...(shouldHideDrafts && !restFilters.status?.length
        ? {
            status: Object.values(FormSubmissionStatus).filter(
              (s) => s !== FormSubmissionStatus.DRAFT
            ),
          }
        : {}),
      ...(filteredAnswers ? { id: Array.from(filteredAnswers) } : {}),
    },
    meta: { answers: true },
  };

  const { data: formSubmissionData, isLoading } = useQuery({
    queryKey: [Resources.FORM_SUBMISSION, params],
    queryFn: () => {
      if (!form_schema_id) return;

      return FormSubmissionAdapter.getList(params);
    },
    enabled:
      Boolean(form_schema_id) &&
      !answersQueryIsLoading &&
      !answersQueryIsFetching,
    staleTime: 2 * 60 * 1000,
  });

  const { measurementUnits } = useMeasurementTypes();

  const dynamicSubmissionsHeaders = useMemo(
    () => getHeadersFromSchema(measurementUnits, schemaQuery.data),
    [schemaQuery.data]
  );

  const submissionsHeaders: Array<HeaderType<FormSubmissionType>> = [
    {
      label: "Submission Name",
      key: "id",
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToFormSubmissionDetail(item.id)}>
          {`${item?.form_schema?.name} - ${item?.id?.slice(0, 7)}`}
        </RoutingLink>
      ),
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }: { item: FormSubmissionType }) => (
        <DataTable.DataRow.PillCell
          variant={getStatusType(item.status, "form").type}
          value={toStartCaseString(item.status)}
        />
      ),
    },
    {
      label: "Template",
      key: "template",
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink
          to={linkToFormTemplateDetail(
            item.form_schema?.form_category_id,
            item.form_schema_id
          )}
        >
          {item.form_schema?.name}
        </RoutingLink>
      ),
    },
    {
      label: "Submission Date",
      key: "created_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
    ...dynamicSubmissionsHeaders,
  ];

  const items = useMemo(
    (): Array<FormSubmissionType & { name: string }> =>
      formSubmissionData?.data.reduce(
        (
          itemsWithNames: Array<FormSubmissionType & { name: string }>,
          item: FormSubmissionType
        ) => {
          const name = `${item?.form_schema?.name} - ${item?.id?.slice(0, 7)}`;
          const newItem = { ...item, name };
          if (
            newItem.name
              .toLowerCase()
              .includes(filters.name?.toLowerCase() ?? "")
          ) {
            itemsWithNames.push(newItem);
          }

          return itemsWithNames;
        },
        []
      ),
    [formSubmissionData, filters.name]
  );

  useEffect(() => {
    if (answersQueryHasNextPage && !answersQueryIsFetching) {
      answerQueryFetchNextPage();
    }
  }, [answersQueryHasNextPage, answersQueryIsFetching]);

  useEffect(() => {
    const newAnswerIds =
      answersQuery?.pages.flatMap((page) => page?.data.map((d) => d.id)) ?? [];
    setFilteredAnswers(() => {
      const newSet = new Set<string>();
      newAnswerIds.forEach((answerId) => {
        if (answerId) {
          newSet.add(answerId);
        }
      });
      return newSet;
    });
  }, [answersQuery?.pages]);

  useEffect(() => {
    refetchAnswers();
  }, [refetchAnswers, form_schema_id]);

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Form Submissions",
      }}
      filterComponent={
        <FormSubmissionsTableFilterArea
          defaultValues={filtersDefaultValues}
          filterConfigStorageKey={filterConfigStorageKey}
          shouldShowFormSubmissionSearchInput
          shouldShowSavedFilters
          filterDrawerContentSlot={
            <FormSubmissionsTableFilterAreaDrawerContent
              hasStatusFilter
              hasSubmittedByFilter
              formSchemaId={form_schema_id}
            />
          }
        />
      }
      dataTableProps={{
        items,
        headers: submissionsHeaders,
        isLoading: isLoading || answersQueryIsLoading,
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: formSubmissionData?.total_entries,
        },
        onPaginationChange: updateTableState,
        onSortChange: updateTableState,
        getItemActions: ({ item }: { item: FormSubmissionType }) =>
          selectedFormSubmissions?.find((selected) => selected.id === item.id)
            ? [
                {
                  label: "Remove",
                  buttonProps: {
                    onClick: () => deselectFormSubmission(item?.id),
                    icon: "minus-circle",
                  },
                },
              ]
            : [
                {
                  label: "Add",
                  buttonProps: {
                    onClick: () => selectFormSubmission(item),
                    icon: "plus-circle",
                  },
                },
              ],
      }}
    />
  );
};
