import {
  useDeleteNetwork,
  useGetOneNetwork,
} from "#hooks/adapters/useNetworks";
import { useNavigate, useParams, useSearchParams } from "#routers/hooks";
import { NetworkListRoute } from "#routes/organization/networks";
import { NetworkCalculationTab } from "#routes/organization/networks/[networkId]/detail/NetworkCalculationTab";
import { NetworkResultsTab } from "#routes/organization/networks/[networkId]/detail/NetworkResultsTab/NetworkResultsTab";
import EditAssetGroupDialog from "#src/batteries-included-components/Dialogs/EditAssetGroupDialog";
import { useCreateEditMetaSegments } from "#src/components/hooks/useCreateEditMetaSegments";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#utils/route";
import {
  Button,
  Dialog,
  Page,
  Pill,
  Skeleton,
  Tab,
} from "@validereinc/common-components";
import { getYearMonthFromDateRange } from "@validereinc/utilities";
import React, { useState } from "react";
import { NetworkCalculationTabV2 } from "./NetworkCalculationTabV2";
import { NetworkDetailsTab } from "./NetworkDetailsTab/NetworkDetailsTab";
import { NetworkDetailRoute } from "./index";

const DEFAULT_TAB_KEY = "details";

export const NetworkDetailPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [assetIdToEdit, setAssetIdToEdit] = useState<string | undefined>();
  const { networkId } = useParams<{ networkId: string }>();

  const assetsSharedStorageKeys = useStorageKey("assets-shared");
  const [filters] = useSessionStickyState<{ period: { from?: string } }>(
    {},
    assetsSharedStorageKeys.viewConfigStorageKey
  );

  const { data: assetGroup, isLoading } = useGetOneNetwork(networkId, {
    period: getYearMonthFromDateRange(filters.period),
  });
  const createEditMetaSegments = useCreateEditMetaSegments({
    editedAt: assetGroup?.updated_at,
    createdAt: assetGroup?.created_at,
  });

  const [breadcrumbs] = useBreadcrumbsFromRoute(NetworkDetailRoute, {
    detail: { title: assetGroup?.name },
  });

  const deleteNetwork = useDeleteNetwork({
    onSuccess: () => {
      navigate({ pathname: NetworkListRoute.toLink() });
    },
  });

  const onActiveTabKeyChange = (tabKey: string) => {
    setSearchParams({ ...searchParams, tab: tabKey });
  };

  const onDeleteNetwork = async () => {
    deleteNetwork.mutate(networkId);
    setIsDeleteModalOpen(false);
  };

  const onClickEditNetwork = () => {
    setAssetIdToEdit(networkId);
  };

  return (
    <>
      <Page
        title={
          assetGroup ? (
            <>
              {assetGroup?.name}{" "}
              <Pill
                variant={
                  assetGroup?.status === "active" ? "success" : "default"
                }
                hasDot={false}
              >
                {assetGroup?.status}
              </Pill>
            </>
          ) : (
            NetworkDetailRoute.title
          )
        }
        category={NetworkDetailRoute.previous?.title}
        breadcrumbs={breadcrumbs}
        activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
        onActiveTabKeyChange={onActiveTabKeyChange}
        actionRow={[
          <Button
            key="delete-network-action"
            variant="error-outline"
            onClick={() => setIsDeleteModalOpen(true)}
            disabled={deleteNetwork.isLoading}
          >
            Delete
          </Button>,
          <Button
            onClick={onClickEditNetwork}
            key="edit"
          >
            Edit
          </Button>,
        ]}
        renderMeta={
          createEditMetaSegments.length === 0
            ? undefined
            : ({ MetaSegments }) => (
                <MetaSegments values={createEditMetaSegments} />
              )
        }
      >
        <Tab
          title="Details"
          tabKey="details"
        >
          <NetworkDetailsTab
            viewConfigStorageKey={assetsSharedStorageKeys.viewConfigStorageKey}
          />
        </Tab>
        <Tab
          title="Calculations"
          tabKey="calculations"
        >
          {assetGroup?.default_estimation_method ? (
            <Skeleton isLoading={isLoading}>
              <NetworkCalculationTabV2 />
            </Skeleton>
          ) : (
            <Skeleton isLoading={isLoading}>
              <NetworkCalculationTab />
            </Skeleton>
          )}
        </Tab>
        <Tab
          title="Results"
          tabKey="results"
        >
          <NetworkResultsTab />
        </Tab>
      </Page>
      <Dialog
        key="delete Modal"
        title={`Delete ${assetGroup?.name}?`}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        actionRow={[
          <Button
            key="delete-dialog-action"
            variant="error"
            onClick={onDeleteNetwork}
          >
            Delete
          </Button>,
        ]}
      >
        Are you sure you want to delete this network? This action cannot be
        undone.
      </Dialog>
      <EditAssetGroupDialog
        assetGroupId={assetIdToEdit}
        onClose={() => setAssetIdToEdit(undefined)}
      />
    </>
  );
};
