import { useNavigate } from "#routers/hooks";
import { linkToFacilityDetail } from "#routes/organization/facilities/[facilityId]";
import { useGetOneReportingGroup } from "#src/components/hooks/adapters/useReportingGroups";
import { FacilityContext } from "#src/contexts/FacilityContext";
import { RecordContext } from "#src/contexts/RecordContext";
import useLocalization from "#src/hooks/useLocalization";
import { KeyValuePanel, Link, Pill } from "@validereinc/common-components";
import React, { useContext } from "react";

export const RecordFacilitySummaryPanel = () => {
  const navigate = useNavigate();
  const { localize } = useLocalization();
  const { facility } = useContext(FacilityContext) ?? {};
  const { record } = useContext(RecordContext) ?? {};
  const reportingGroupQuery = useGetOneReportingGroup(
    record?.reporting_group_id
  );
  return (
    <KeyValuePanel
      panelMaxColumnCount={2}
      panelKeyValueListProps={{ variant: "shaded" }}
      panelProps={{ title: "Details", isFluidY: false }}
      data={[
        {
          title: "Reporting Scenario",
          value: reportingGroupQuery.data?.data?.name ? (
            <Pill
              isCapitalized={false}
              variant="primary"
              hasDot={false}
              isBordered
            >
              {reportingGroupQuery.data?.data?.name}
            </Pill>
          ) : null,
        },
        {
          title: localize("Facility"),
          value: (
            <Link
              onClick={() =>
                navigate({
                  pathname: linkToFacilityDetail(facility?.id),
                })
              }
              label={facility?.name ?? "-"}
            />
          ),
        },
      ]}
    />
  );
};
