import { restAPI } from ".";
import { CommentEntity, CommentSchemaType } from "../schemas/CommentSchemas";
import {
  CreateOneRequestType,
  CreateOneResponseType,
  GetOneRequestType,
  GetOneResponseType,
  type ResourceServiceType,
} from "../util";

interface CommentAdapterType
  extends Pick<
    ResourceServiceType<CommentSchemaType>,
    "createOne" | "deleteOne"
  > {
  feed: {
    getOne: (
      params: GetOneRequestType<
        Pick<CommentSchemaType, "entity_type" | "additional_identifier"> & {
          created_before: string;
        }
      >
    ) => Promise<GetOneResponseType<CommentSchemaType[]>>;
  };
  createOne: (
    params: CreateOneRequestType<
      Pick<
        CommentSchemaType,
        "text" | "title_context" | "commented_at" | "files"
      >,
      Pick<
        CommentSchemaType,
        "entity_type" | "entity_id" | "additional_identifier"
      >
    >
  ) => Promise<CreateOneResponseType<CommentSchemaType>>;
}

export const CommentAdapter: CommentAdapterType = {
  feed: {
    /**
     * Get a single feed of comments associated with an entity
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/comments/get_comments
     */
    getOne: async ({
      id: entity_id,
      meta = {
        entity_type: CommentEntity.WORKFLOW,
        additional_identifier: "",
        created_before: "",
      },
    }) => {
      const resp = await restAPI.nodeAPI.GET<CommentSchemaType[]>({
        endpoint: `/comments/${meta.entity_type}/${entity_id}`,
        query: {
          ...(meta
            ? {
                ...(meta.created_before
                  ? { created_before: meta.created_before }
                  : {}),
                ...(meta.additional_identifier
                  ? { additional_identifier: meta.additional_identifier }
                  : {}),
              }
            : {}),
        },
      });
      return { data: resp };
    },
  },
  /**
   * Create a comment
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/comments/create_comment
   */
  createOne: async ({
    data,
    meta = {
      entity_type: CommentEntity.WORKFLOW,
      entity_id: "",
      additional_identifier: "",
    },
  }) => {
    const resp = await restAPI.nodeAPI.POST<CommentSchemaType>({
      endpoint: `/comments/${meta.entity_type}/${meta.entity_id}`,
      query: {
        ...(meta.additional_identifier
          ? { additional_identifier: meta.additional_identifier }
          : {}),
      },
      body: data,
    });

    return { data: resp };
  },
  /**
   * Delete a comment
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/comments/delete_comment
   */
  deleteOne: async ({ id }) =>
    await restAPI.nodeAPI.DELETE({
      endpoint: `/comments/${id}`,
    }),
};
