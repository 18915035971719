import React from "react";

import { RolesKeyValuePanel } from "./RolesKeyValuePanel";

import classNames from "classnames/bind";
import styles from "./UserRolesTab.module.scss";

const cx = classNames.bind(styles);

export const UserRolesTab = () => {
  return (
    <div className={cx("container")}>
      <div className={cx("contentContainer")}>
        <RolesKeyValuePanel />
      </div>
    </div>
  );
};
