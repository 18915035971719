import { useCreateOneComment } from "#src/components/hooks/adapters/useComments";
import { ExceptionUtils } from "#src/utils/exception";
import { useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Form,
  TextAreaInput,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import {
  CommentEntity,
  CommentSchemaType,
  Resources,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import { default as React, useState } from "react";
import styles from "./CommentForm.module.css";

const cx = classNames.bind(styles);

export const CommentForm = ({
  entity_type = CommentEntity.WORKFLOW,
  entity_id,
  additional_identifier = "",
  files = [],
  canCreateComments = false,
  onCreateCallback,
}: Partial<
  Pick<
    CommentSchemaType,
    "entity_type" | "entity_id" | "additional_identifier" | "files"
  > & {
    canCreateComments?: boolean;
    onCreateCallback: (newCommentId: string) => void;
  }
>) => {
  const [isFormShown, setIsFormShown] = useState(false);
  const queryClient = useQueryClient();
  const { addAlert } = useAlert();

  const form = useForm({
    defaultValues: { comment: "" },
    mode: "onBlur",
  });

  const createCommentMutation = useCreateOneComment({
    onSuccess: (response: { data: CommentSchemaType }) => {
      queryClient.invalidateQueries({
        queryKey: [Resources.COMMENT],
      });
      onCreateCallback?.(response.data.id);
    },
    onError: (error: unknown) => {
      ExceptionUtils.reportException(error, "error", {
        hint: "Error creating comment",
      });
      addAlert?.({
        variant: "error",
        message: "Failed to create comment.",
      });
    },
    noAlerts: true,
  });

  const onSubmit = () => {
    createCommentMutation.mutate({
      data: {
        text: form.getValues("comment"),
        files,
      },
      meta: {
        entity_type,
        entity_id,
        additional_identifier,
      },
    });

    form.reset();
  };

  return (
    <>
      {isFormShown ? (
        <div className={cx("container")}>
          <div>
            <Form
              {...form}
              onSubmit={onSubmit}
            >
              <TextAreaInput
                name="comment"
                label="Comment"
                placeholder="Enter Comment..."
                isLabelShown={false}
                isRequired
              />
            </Form>
          </div>
          <div className={cx("button-container")}>
            <Button
              className={cx("button")}
              onClick={() => {
                setIsFormShown(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className={cx("button")}
              variant="primary"
              onClick={onSubmit}
              disabled={
                !form.formState.isDirty ||
                !form.formState.isValid ||
                !canCreateComments
              }
            >
              Comment
            </Button>
          </div>
        </div>
      ) : (
        <Button
          className={cx("button")}
          onClick={() => setIsFormShown(true)}
        >
          Comment
        </Button>
      )}
    </>
  );
};
