import { RecordValueDetailsPanel } from "#batteries-included-components/Panels/DetailsPanel/RecordValueDetailsPanel";
import { RecordValueSourcesTablePanel } from "#batteries-included-components/Panels/TablePanels/RecordValueSourcesTablePanel";
import { useParams } from "#routers/hooks";
import BulkRecordValueStatusChangeButton from "#src/batteries-included-components/Buttons/BulkRecordValueStatusChangeButton/BulkRecordValueStatusChangeButton";
import { RecordValueSummaryPanel } from "#src/batteries-included-components/Panels/SummaryPanels/RecordValueSummaryPanel/RecordValueSummaryPanel";
import { RecordValueActivityLogTablePanel } from "#src/batteries-included-components/Panels/TablePanels/RecordValueActivityLogTablePanel";
import { useGetOneFlow } from "#src/components/hooks/adapters/useFlows";
import { useActionDetails } from "#src/components/hooks/useActionDetails";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { RecordContext, RecordProvider } from "#src/contexts/RecordContext";
import useLocalization from "#src/hooks/useLocalization";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { Page, Pill, PillProps } from "@validereinc/common-components";
import {
  AssetType,
  RecordValueStatusType,
  Resources,
} from "@validereinc/domain";
import { yearMonthName } from "@validereinc/utilities";
import React, { useContext } from "react";
import {
  FlowRecordValueDetailRoute,
  FlowRecordValuePageParamsType,
} from "./index";

const FlowRecordValueDetailPageContent = ({ flowId }: { flowId: string }) => {
  const { localize } = useLocalization();
  const params = useParams<FlowRecordValuePageParamsType>();
  const { record, refetch } = useContext(RecordContext) ?? {};
  const { data: flow } = useGetOneFlow(
    { flowId, period: record?.year_month },
    { enabled: !!record?.year_month }
  );

  const { measurementTypes } = useMeasurementTypes();
  const measurementType = measurementTypes?.find(
    ({ id }) => id === params.measurementType
  );

  const recordPageTitle = record
    ? `${yearMonthName(record.year_month)} Record`
    : FlowRecordValueDetailRoute.previous?.title;

  const pageTitle = record
    ? `${yearMonthName(record.year_month)} ${measurementType?.name}`
    : FlowRecordValueDetailRoute.title;

  const [breadcrumbs] = useBreadcrumbsFromRoute(FlowRecordValueDetailRoute, {
    detail: { title: flow?.name },
    recordId: { title: recordPageTitle },
    measurementType: { title: pageTitle },
  });

  const recordValue = record?.values.find(
    ({ measurement_type }) => measurement_type === measurementType?.id
  );

  const editInfo = useActionDetails({
    type: "edit",
    date: recordValue?.updated_at,
    byUserId: recordValue?.updated_by,
  });

  const tableKeys = useStorageKey(
    `activity-logs-${Resources.RECORD_VALUE}-${recordValue?.id}`
  );

  const recordStatusOptions: Record<
    RecordValueStatusType,
    { label: string; variant: PillProps["variant"] }
  > = {
    locked: { label: "Locked", variant: "default" },
    unlocked: { label: "Unlocked", variant: "info" },
  };

  return (
    <Page
      category={`${localize("Flow")} Record`}
      title={
        !record ? (
          pageTitle
        ) : (
          <>
            {pageTitle}&nbsp;
            <Pill
              variant={
                recordValue?.status
                  ? recordStatusOptions[recordValue?.status].variant
                  : "default"
              }
              hasDot={false}
            >
              {recordValue?.status
                ? recordStatusOptions[recordValue?.status].label
                : ""}
            </Pill>
          </>
        )
      }
      breadcrumbs={breadcrumbs}
      actionRow={
        <BulkRecordValueStatusChangeButton
          recordId={record?.id ?? ""}
          measurementTypes={[recordValue?.measurement_type ?? ""]}
          onSuccess={() => {
            refetch?.();
          }}
          variant="primary"
          label="Change Record Value Status"
        />
      }
      renderMeta={
        !editInfo.description
          ? undefined
          : ({ MetaSegments }) => (
              <MetaSegments values={[editInfo.description]} />
            )
      }
    >
      <RecordValueSummaryPanel />
      <RecordValueDetailsPanel />
      <RecordValueSourcesTablePanel type={AssetType.FLOW} />
      <RecordValueActivityLogTablePanel
        recordId={record?.id ?? ""}
        measurement_type={recordValue?.measurement_type}
        {...tableKeys}
      />
    </Page>
  );
};

export const FlowRecordValueDetailPage = () => {
  const { flowId } = useParams<{ flowId: string }>();

  return (
    <RecordProvider>
      <FlowRecordValueDetailPageContent flowId={flowId} />
    </RecordProvider>
  );
};
