import { ConditionFunctionTypes } from "@validereinc/domain-controllers/logic/forms";
import { AssertIsBeforeDate } from "./assert";

// IMPROVE: all the fns in this file need a code smell cleanup. They all have bad names, signatures, and logic flow.

export function ValidateTextInput(
  title,
  input,
  isRequired,
  allowSpecialCharacter
) {
  if (isRequired && !input) {
    return `${title} can not be empty`;
  } else if (
    !allowSpecialCharacter &&
    String(input).search(/^[a-z0-9 _]+$/i) === -1
  ) {
    return `${title} cannot contain special characters`;
  } else if (input && String(input).length > 100) {
    return `${title} can not be longer than 100 characters.`;
  } else {
    return null;
  }
}

/**
 * @deprecated should not be used. Use email validation built into Zod, our schema validation library in-use in our domain package.
 */
export function ValidateTextInputEmail(title, email, isRequired) {
  if (isRequired && !email) {
    return `${title} can not be empty`;
  } else if (
    email &&
    email.search(
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ) === -1
  ) {
    return `Invalid ${title} Input`;
  } else {
    return null;
  }
}

/**
 * @deprecated should not be used. Use the TelephoneInput from the common-components package instead.
 */
export function ValidateTextInputPhone(title, phone, isRequired) {
  if (isRequired && !phone) {
    return `${title} can not be empty`;
  } else if (
    phone &&
    phone.search(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/) === -1 &&
    phone !== ""
  ) {
    return `Invalid ${title} format (e.g 555-555-5555)`;
  } else {
    return null;
  }
}

export function ValidateInputArray(
  title,
  array,
  isRequired,
  maxLength,
  minLength
) {
  if (isRequired && (!array || array.length === 0)) {
    return `${title} can not be empty`;
  } else if (maxLength && array.length > maxLength) {
    return `${title} maximum is ${maxLength}`;
  } else if (minLength && array.length < minLength) {
    return `${title} minimum is  ${minLength}`;
  } else {
    return null;
  }
}

export function ValidateId(title, id, isRequired) {
  if (isRequired && !id) {
    return `${title} can not be empty`;
  } else {
    return null;
  }
}

export function ValidateTimeZone(title, timezone) {
  if (!timezone) {
    return `${title} can not be empty`;
  }

  return null;
}

export function ValidateDateRange(title, startTime, endTime) {
  if (!endTime || !startTime) {
    return `${title} can not be empty`;
  }

  if (AssertIsBeforeDate(endTime, startTime, "second")) {
    return `End ${title} must be after start ${title}`;
  }

  return null;
}

export function ValidateNumberInput(title, input, isRequired, isInteger) {
  if (isRequired && !input) {
    return `${title} can not be empty`;
  } else if (input && isInteger && input % 1 !== 0) {
    return `${title} must be an integer`;
  } else if (input && String(input).search(/^\d*\.?\d*$/) === -1) {
    return `${title} must be a number`;
  }

  return null;
}

export function ValidateFirstCharIsLetter(word) {
  const regex = /^[A-Za-z].*/;

  if (typeof word !== "string") {
    return false;
  }

  return word.match(regex) ? true : false;
}

/**
 * CSV fields starting with certain characters can be interpreted as a formula
 * in programs like Excel, and can be used to start a CSV injection attack.
 */
export function ValidateCSVSafeInput(title, word) {
  const regex = /^([+\-=@]).*/;

  if (typeof word !== "string") {
    return null;
  }

  const match = word.trim().match(regex);
  if (match) {
    return `${title} cannot start with "${match[1]}"`;
  } else {
    return null;
  }
}

/*
TODO: update QuestionType to include conditions property
Can we import QuestionType from @validereinc/domain into common-component?!
*/
type ConditionQuestionType = {
  data_type: string;
  conditions?: Record<
    string,
    {
      [key in ConditionFunctionTypes]: boolean | number | string;
    }
  >;
  description: string;
  is_required: boolean;
  prompt: string;
  type: string;
};

type SectionType = {
  id: string;
  name: string;
  questions: ConditionQuestionType[];
  description: string;
  is_repeatable: boolean;
};

export const getConditionalWatchSet = (displaySections: SectionType[]) => {
  if (!displaySections) {
    return new Set<string>([]);
  }

  const conditionalWatchSet: Set<string> = new Set<string>([]);
  displaySections.forEach((section) => {
    section.questions.forEach((question) => {
      if (question.conditions) {
        const watchQuestionName = Object.keys(question.conditions)[0];
        conditionalWatchSet.add(watchQuestionName);
      }
    });
  });
  return conditionalWatchSet;
};

export const getTotalErrorsUponSubmission = (errors: any) => {
  if (!errors) {
    return 0;
  }
  if (errors?.answers) {
    let errorsCount = 0;
    const answers = errors?.answers;
    // get answer entries
    const answerEntries = Object.entries(answers);
    answerEntries.forEach((answerEntry) => {
      answerEntry.forEach((answer) => {
        if (Array.isArray(answer)) {
          const answerObject = answer[0];
          errorsCount += answerObject ? Object.keys(answerObject).length : 0;
        }
      });
    });
    return errorsCount;
  }

  return 0;
};
