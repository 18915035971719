import { useParams, useSearchParams } from "#src/Routers/hooks";
import { CreateWorkflowDialog } from "#src/batteries-included-components/Dialogs/CreateWorkflowDialog";
import { WorkflowsListLayout } from "#src/batteries-included-components/Layouts/Workflows/List";
import { StatusVariants } from "#src/components/Common/SummaryInformation";
import { useGetOneWorkflowTemplate } from "#src/components/hooks/adapters/useWorkflowTemplates";
import { useActionDetails } from "#src/components/hooks/useActionDetails";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { WorkflowsRoutePath } from "#src/routes/workflows";
import { WorkflowCategoriesRoutePath } from "#src/routes/workflows/categories";
import { WorkflowCategoryDetailsRoutePath } from "#src/routes/workflows/categories/[workflowCategoryId]";
import { displayLocalizedAssetType } from "#src/utils/display";
import { RoutePath, useBreadcrumbsFromRoutes } from "#src/utils/route";
import { useQuery } from "@tanstack/react-query";
import { Button, Page, Pill, Tab } from "@validereinc/common-components";
import { UsersAdapter, WorkflowTemplateType } from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useState } from "react";
import { WorkflowTemplateDetailsRoutePath } from ".";
import { WorkflowTemplateAssetTable } from "./WorkflowTemplateAssetTable";
import { WorkflowTemplateKeyValuePanel } from "./WorkflowTemplateKeyValuePanel";
import styles from "./WorkflowTemplatePage.module.scss";

const cx = classNames.bind(styles);

const DEFAULT_TAB_KEY = "overview";

export const WorkflowTemplatePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [templateIdForDialogOpen, setTemplateIdForDialogOpen] =
    useState<string>("");

  const storageKeys = useStorageKey("workflows-template");
  const { workflowTemplateId } = useParams<{
    workflowTemplateId: string;
  }>();

  const { data, isLoading } = useGetOneWorkflowTemplate({
    id: workflowTemplateId,
  });

  const templateDetails = data?.data;

  const editInformation = useActionDetails({
    type: "edit",
    date: templateDetails?.updated_at,
    byUserId: templateDetails?.updated_by,
  });

  const { data: ownerUser } = useQuery(
    ["users", templateDetails?.workflow_owner_id],
    async () => {
      if (!templateDetails?.workflow_owner_id) {
        return;
      }

      return UsersAdapter.getOne({
        id: templateDetails?.workflow_owner_id,
      });
    },
    {
      enabled: Boolean(templateDetails?.workflow_owner_id),
      staleTime: 5 * 60 * 1000,
      select: (resp) => resp?.data,
    }
  );

  const [breadcrumbs] = useBreadcrumbsFromRoutes(
    [
      [WorkflowsRoutePath, {}],
      [WorkflowCategoriesRoutePath, {}],
      [
        WorkflowCategoryDetailsRoutePath,
        {
          title: templateDetails?.workflow_category.name,
          pathParams: {
            workflowCategoryId: templateDetails?.workflow_category.id ?? "",
          },
        },
      ],
      [
        new RoutePath({
          path: "",
          isPresentational: true,
          title: "Workflow Templates",
        }),
        {},
      ],
      [
        WorkflowTemplateDetailsRoutePath,
        {
          title: templateDetails?.name,
        },
      ],
    ],
    !!templateDetails
  );

  const onOpenCreateWorkflowDialog = () =>
    setTemplateIdForDialogOpen(workflowTemplateId);

  const onCloseCreateWorkflowDialog = () => setTemplateIdForDialogOpen("");

  const localizedAssetType = displayLocalizedAssetType(
    templateDetails?.asset_type
  );
  const getStatusPillVariant = (status: WorkflowTemplateType["status"]) => {
    switch (status) {
      case "active":
        return StatusVariants.ACTIVE;
      case "draft":
        return StatusVariants.INFO;
      case "archive":
      default:
        return StatusVariants.INACTIVE;
    }
  };

  return (
    <>
      <Page
        isLoading={isLoading}
        category={WorkflowTemplateDetailsRoutePath.previous?.title}
        title={
          templateDetails ? (
            <>
              {templateDetails.name}&nbsp;
              <Pill
                variant={getStatusPillVariant(templateDetails.status)}
                hasDot={false}
              >
                {templateDetails.status}
              </Pill>
            </>
          ) : (
            WorkflowTemplateDetailsRoutePath.title
          )
        }
        breadcrumbs={breadcrumbs}
        actionRow={[
          <Button
            key="create-workflow-action"
            variant="primary"
            onClick={onOpenCreateWorkflowDialog}
          >
            Create Workflow
          </Button>,
        ]}
        renderMeta={
          !editInformation.description
            ? undefined
            : ({ MetaSegments }) => (
                <MetaSegments values={[editInformation.description]} />
              )
        }
        activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
        onActiveTabKeyChange={(tabKey: string) => {
          setSearchParams({ tab: tabKey });
        }}
      >
        <Tab
          title="Overview"
          tabKey="overview"
        >
          <div>
            <WorkflowTemplateKeyValuePanel
              templateDetails={templateDetails}
              isLoading={isLoading}
              workflowOwnerName={ownerUser?.name}
            />
            <div className={cx("allWorkflowsContainer")}>
              <WorkflowsListLayout
                tablePanelProps={{
                  ...storageKeys,
                  isWithinWorkflowTemplateDetails: true,
                  overrideFilters: {
                    templateId: templateDetails?.id,
                  },
                }}
                defaultAssetType={templateDetails?.asset_type}
              />
            </div>
          </div>
        </Tab>
        {templateDetails?.asset_type ? (
          <Tab
            title={localizedAssetType}
            tabKey={localizedAssetType.toLowerCase()}
          >
            <WorkflowTemplateAssetTable
              assetType={templateDetails?.asset_type}
              assetsQueryFilters={templateDetails?.asset_filter}
            />
          </Tab>
        ) : null}
      </Page>
      <CreateWorkflowDialog
        onClose={onCloseCreateWorkflowDialog}
        templateId={templateIdForDialogOpen}
      />
    </>
  );
};
