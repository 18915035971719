import { useNavigate } from "#routers/hooks";
import { useGetOneReportingGroup } from "#src/components/hooks/adapters/useReportingGroups";
import { RecordProvider } from "#src/contexts/RecordContext";
import {
  RecordValueContext,
  RecordValueProvider,
} from "#src/contexts/RecordValueContext";
import { getSourceName, getSourcePath } from "#utils/recordUtils";
import { KeyValuePanel, Link, Pill } from "@validereinc/common-components";
import {
  AssetType,
  RecordValueConfigurationTypeType,
  formatAggregationFunctionType,
  formatRecordValueConfigurationType,
} from "@validereinc/domain";
import { datetimeFormatter, yearMonthName } from "@validereinc/utilities";
import React, { useContext } from "react";

export const RecordValueDetailsPanelContent = () => {
  const navigate = useNavigate();
  const { record, users, recordValue, isLoading } =
    useContext(RecordValueContext) || {};

  const sources = recordValue?.configuration?.sources ?? [];
  const configurationType =
    recordValue?.configuration.configuration_type ??
    RecordValueConfigurationTypeType.MANUAL;
  const reportingGroupQuery = useGetOneReportingGroup(
    record?.reporting_group_id
  );

  return (
    <KeyValuePanel
      panelMaxColumnCount={2}
      panelProps={{ title: "Details", isFluidY: false, loaded: !isLoading }}
      data={[
        {
          title: "Reporting Scenario",
          value: reportingGroupQuery.data?.data?.name ? (
            <Pill
              isCapitalized={false}
              variant="primary"
              hasDot={false}
              isBordered
            >
              {reportingGroupQuery.data?.data?.name}
            </Pill>
          ) : null,
        },
        {
          title: "Source Type",
          value: formatRecordValueConfigurationType(configurationType),
        },
        {
          title: "Aggregation Function",
          value: formatAggregationFunctionType(
            recordValue?.configuration?.aggregate_function
          ),
        },
        {
          title: "Source",
          value: sources.length ? (
            sources.length === 1 ? (
              <Link
                onClick={() =>
                  navigate({
                    pathname: getSourcePath(configurationType, sources[0]),
                    query:
                      sources[0]?.estimation_method_entity_type ===
                      AssetType.ASSET_GROUP
                        ? {
                            tab: "calculations",
                            period: sources[0].year_month,
                          }
                        : {},
                  })
                }
                label={getSourceName(configurationType, sources[0])}
              />
            ) : (
              "Many"
            )
          ) : (
            "-"
          ),
        },
        {
          title: "Last Updated By",
          value: users?.find(({ id }) => id === recordValue?.updated_by)?.name,
        },
        {
          title: "Period",
          value: record?.year_month && yearMonthName(record.year_month),
        },
        {
          title: "Last Updated At",
          value:
            recordValue?.updated_at &&
            datetimeFormatter(new Date(recordValue?.updated_at)),
        },
      ]}
    />
  );
};

export const RecordValueDetailsPanel = () => {
  return (
    <RecordProvider>
      <RecordValueProvider>
        <RecordValueDetailsPanelContent />
      </RecordValueProvider>
    </RecordProvider>
  );
};
