import { z } from "zod";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";

export const CommentEntity = {
  ASSET: "asset",
  REPORT: "report",
  WORKFLOW: "workflow",
} as const;
export type CommentEntityType =
  (typeof CommentEntity)[keyof typeof CommentEntity];

export const CommentFileSchema = z.object({
  comment_id: z.string().uuid(),
  file_id: z.string(),
  file_name: z.string().max(128),
  created_at: z.date().or(z.string().datetime()),
});
export type CommentFileSchemaType = z.infer<typeof CommentFileSchema>;

export const CommentSchema = z
  .object({
    company_id: z.string().uuid(),
    text: z.string().max(5120),
    title_context: z.string().max(64).optional(),
    is_deleted: z.boolean().default(false),
    commented_at: z.date().or(z.string().datetime()),
    entity_type: z.enum([
      CommentEntity.ASSET,
      CommentEntity.REPORT,
      CommentEntity.WORKFLOW,
    ]),
    entity_id: z.string().uuid(),
    additional_identifier: z
      .string()
      .optional()
      .describe(
        "Used to group comments by an additional context e.g. a workflow task"
      ),
    files: z.array(CommentFileSchema),
    user: z.object({ id: z.string().uuid(), name: z.string() }),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema)
  .describe("Represents a Comment in the Node API");
export type CommentSchemaType = z.infer<typeof CommentSchema>;

export const CommentSchemaGetListFilterSchema = z.object({
  id: z.string().optional(),
  created_before: z.string().optional(),
  additional_identifier: z.string().optional(),
});
export type CommentSchemaGetListFilterType = z.infer<
  typeof CommentSchemaGetListFilterSchema
>;
